import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CHANNEL_PERSONA_STATS_BENCHMARK,
  JOB,
  JOB_ATS_STAGES,
  JOB_SETUP,
  JOB_SETUP_STEP,
  LIST_TAG,
  APPLICATION_PORTAL_JOB,
  JOB_BOARDS,
  LIST_REFERRERS,
  LIST_JOB_REFERRERS,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiListJobsRequest,
  DashboardJob,
  DeactivateJobDeactivationReasonEnum,
  SnoozeJobSnoozedReasonEnum,
  JobSetup,
  ApiApiGetJobFunnelStatsRequest,
  JobOutreachStats,
  ApiApiGetJobOutreachStatsRequest,
  JobOutreachBenchmarks,
  JobReportMeta,
  JobOutboundStats,
  ApiApiGetJobOutboundStatsRequest,
  JobInboundStats,
  ApiApiGetJobInboundStatsRequest,
  JobReferralsStats,
  ApiApiGetJobReferralsStatsRequest,
  JobFunnelStats,
  DeactivationStats,
  JobSetupAtsSyncSettingEnum,
  SyncAndListJobAtsStages,
  JobSetupStepsWithSetupSummaryState,
  ListLocationsResponse,
  JobLocation,
  JobCompensation,
  ApplicationPortalJob,
  UpdateJobHiringTeam,
  UpdateJobVisibility,
} from "services/openapi";
import { BenchmarkChannelPersonaStats } from "services/openapi/models/BenchmarkChannelPersonaStats";
import { SendJobSlackChannelInviteResponse } from "services/openapi/models/SendJobSlackChannelInviteResponse";
import { DjangoListResponseType } from "types";
import { showErrorToast, showSuccessToast } from "utils/showToast";

const jobAdapter = createEntityAdapter<DashboardJob>();

interface UpdateHiringTeamArgs extends UpdateJobHiringTeam {
  skipInvalidateTags?: boolean;
}
interface UpdateJobVisibilityArgs extends UpdateJobVisibility {
  id: string;
}

interface DeactivateJobArgs {
  jobId: string;
  deactivationReason: DeactivateJobDeactivationReasonEnum;
  candidatesToReject?: Array<{ [key: string]: string }> | null;
  onlyCancelDiInterviews?: boolean;
}

interface SnoozeJobArgs {
  jobId: string;
  snoozedReason: SnoozeJobSnoozedReasonEnum;
  snoozedUntil: Date;
}

interface UpdateJobSetupArgs {
  id: string;
  decidedToSkipOnboardingCall?: boolean;
  autoSearchAdjustmentsEnabled?: boolean;
  externalNotes?: string | null;
  hiringManagerEmail?: string;
  recruiterEmail?: string;
  title?: string;
  atsJobTitle?: string | null;
  atsJobId?: string | null;
  atsBehalfId?: string | null;
  atsSyncSetting?: JobSetupAtsSyncSettingEnum;
  schedulingCalendarGcalId?: string;
  workSchedulingEmailAliasUser?: number;
  pipelineReviewCallsEnabled?: boolean;
  shouldSendInboundRejectionEmails?: boolean;
  shouldSendApplicationConfirmationEmails?: boolean;
  shouldHideCompensationInternally?: boolean;
  customerFacingNotes?: string;
  compensation?: JobCompensation;
  visaSupport?: boolean;
  locations?: JobLocation[];
  skipToast?: boolean;
  goalHireDate?: Date | null;
}

export const jobEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listJobs: build.query<EntityState<DashboardJob>, ApiApiListJobsRequest | undefined>({
      queryFn: async (
        args = {
          ordering: "-active,title",
        }
      ) => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<DashboardJob>;
        try {
          response = await client.listJobs({ ...args, offset: args.offset ?? 0, limit: args.limit ?? 1000 });
        } catch (error) {
          const userFacingMessage = "Failed to load jobs. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: jobAdapter.addMany(jobAdapter.getInitialState(), response.results),
        };
      },
      providesTags: result => {
        return result
          ? // Successful query
            [...result.ids.map(id => ({ type: JOB, id } as const)), { type: JOB, id: LIST_TAG }]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: JOB, id: LIST_TAG }];
      },
    }),
    getJob: build.query<DashboardJob, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        let job: DashboardJob;
        try {
          job = await client.getJob({ id: jobId });
        } catch (error) {
          const userFacingMessage = "Failed to load job. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: job };
      },
      providesTags: result => {
        return result ? [{ type: JOB, id: result.id } as const] : [];
      },
    }),
    deactivateJob: build.mutation<boolean, DeactivateJobArgs>({
      queryFn: async ({ jobId, deactivationReason, candidatesToReject, onlyCancelDiInterviews }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.deactivateJob({
          id: jobId,
          data: { deactivationReason, candidatesToReject, onlyCancelDiInterviews },
        });
        return { data: result.success };
      },
      invalidatesTags: (result, error, { jobId }) => {
        return [
          { type: JOB, id: jobId },
          { type: JOB_SETUP, id: jobId },
        ];
      },
    }),
    reactivateJob: build.mutation<boolean, string>({
      queryFn: async (jobId: string) => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.reactivateJob({
          id: jobId,
          data: {},
        });
        return { data: result.success };
      },
      invalidatesTags: (result, error, jobId) => {
        return [
          { type: JOB, id: jobId },
          { type: JOB_SETUP, id: jobId },
        ];
      },
    }),
    snoozeJob: build.mutation<boolean, SnoozeJobArgs>({
      queryFn: async ({ jobId, snoozedReason, snoozedUntil }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.snoozeJob({
          id: jobId!,
          data: { snooze: true, snoozedReason, snoozedUntil },
        });
        return { data: result.success };
      },
      invalidatesTags: (result, error, { jobId }) => {
        return [
          { type: JOB, id: jobId },
          { type: JOB_SETUP, id: jobId },
        ];
      },
    }),
    unsnoozeJob: build.mutation<boolean, { jobId: string }>({
      queryFn: async ({ jobId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.snoozeJob({ id: jobId, data: { snooze: false } });
        return { data: result.success };
      },
      invalidatesTags: (result, error, { jobId }) => {
        return [
          { type: JOB, id: jobId },
          { type: JOB_SETUP, id: jobId },
        ];
      },
    }),
    getDeactivationStats: build.query<DeactivationStats, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.getDeactivationStats({ id: jobId });
        return { data: result };
      },
    }),
    getBenchmarkPersonaChannelStats: build.query<BenchmarkChannelPersonaStats, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: BenchmarkChannelPersonaStats;
        try {
          data = await client.getBenchmarkPersonaChannelStats({ id: jobId });
        } catch (error) {
          const userFacingMessage = "Failed to load channel persona benchmark stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data };
      },
      providesTags: (result, error, arg) => (arg ? [{ type: CHANNEL_PERSONA_STATS_BENCHMARK, id: arg }] : []),
    }),
    getJobSetup: build.query<JobSetup, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.getJobSetup({ id: jobId });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: JOB_SETUP, id: result.id } as const] : [];
      },
    }),
    listLocationOptions: build.query<ListLocationsResponse, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        const data = await client.listLocationOptions({});
        return { data };
      },
    }),
    listCurrencyOptions: build.query<Array<string>, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.listCurrencyOptions({});
        return { data };
      },
    }),
    updateJobSetup: build.mutation<JobSetup, UpdateJobSetupArgs>({
      queryFn: async ({
        id,
        decidedToSkipOnboardingCall,
        autoSearchAdjustmentsEnabled,
        externalNotes,
        hiringManagerEmail,
        recruiterEmail,
        title,
        atsJobTitle,
        atsJobId,
        atsBehalfId,
        atsSyncSetting,
        schedulingCalendarGcalId,
        pipelineReviewCallsEnabled,
        workSchedulingEmailAliasUser,
        shouldSendInboundRejectionEmails,
        shouldSendApplicationConfirmationEmails,
        shouldHideCompensationInternally,
        customerFacingNotes,
        compensation,
        visaSupport,
        locations,
        goalHireDate,
        skipToast,
      }) => {
        const { apiApi: client } = await getOpenApiClients({});
        let data: JobSetup;
        try {
          data = await client.partialUpdateJobSetup({
            id,
            data: {
              decidedToSkipOnboardingCall,
              autoSearchAdjustmentsEnabled,
              externalNotes,
              ...(hiringManagerEmail && { hiringManager: { email: hiringManagerEmail } }),
              ...(recruiterEmail && { recruiter: { email: recruiterEmail } }),
              atsJobTitle,
              title,
              atsJobId,
              atsBehalfId,
              atsSyncSetting,
              schedulingCalendarGcalId,
              pipelineReviewCallsEnabled,
              workSchedulingEmailAlias: { userId: workSchedulingEmailAliasUser },
              shouldSendInboundRejectionEmails,
              shouldSendApplicationConfirmationEmails,
              shouldHideCompensationInternally,
              customerFacingNotes,
              compensation,
              visaSupport,
              locations,
              goalHireDate,
            },
          });
        } catch (error) {
          const userFacingMessage = "Failed to update job settings. Please refresh and try again.";
          if (!skipToast) {
            showErrorToast(userFacingMessage);
          }

          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: JOB_SETUP, id: id },
          { type: JOB, id: id },
          { type: APPLICATION_PORTAL_JOB, id },
          { type: JOB_BOARDS, id: LIST_TAG },
          { type: LIST_REFERRERS },
          { type: LIST_JOB_REFERRERS, id },
        ];
      },
    }),
    updateJobHiringTeam: build.mutation<UpdateJobHiringTeam, UpdateHiringTeamArgs>({
      queryFn: async ({ id, hiringManagerId, recruiterId, hiringTeamMemberIds, appendHiringTeamMembers }) => {
        const { apiApi: client } = await getOpenApiClients({});
        let data: UpdateJobHiringTeam;

        try {
          data = await client.updateJobHiringTeam({
            id,
            data: {
              id,
              hiringManagerId,
              recruiterId,
              hiringTeamMemberIds,
              appendHiringTeamMembers,
            },
          });
        } catch (error) {
          const userFacingMessage = "Failed to update hiring team. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data };
      },
      invalidatesTags: (result, error, { id, skipInvalidateTags }) => {
        if (skipInvalidateTags) {
          return [];
        }

        return [
          { type: JOB_SETUP, id: id },
          { type: JOB, id: id },
          { type: APPLICATION_PORTAL_JOB, id },
        ];
      },
    }),
    updateJobVisibility: build.mutation<UpdateJobVisibility, UpdateJobVisibilityArgs>({
      queryFn: async ({ id, isPrivate }) => {
        const { apiApi: client } = await getOpenApiClients({});
        let data: UpdateJobVisibility;

        try {
          data = await client.updateJobVisibility({
            id,
            data: {
              isPrivate,
            },
          });
        } catch (error) {
          const userFacingMessage = "Failed to update job visibility. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: JOB_SETUP, id: id },
          { type: JOB, id: id },
          { type: APPLICATION_PORTAL_JOB, id },
        ];
      },
    }),
    getJobFunnelStats: build.query<JobFunnelStats, ApiApiGetJobFunnelStatsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobFunnelStats;
        try {
          data = await client.getJobFunnelStats({ ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load job funnel stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    getJobOutreachStats: build.query<JobOutreachStats, ApiApiGetJobOutreachStatsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobOutreachStats;
        try {
          data = await client.getJobOutreachStats({ ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load job outreach stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    getJobOutreachBenchmarks: build.query<JobOutreachBenchmarks, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobOutreachBenchmarks;
        try {
          data = await client.getJobOutreachBenchmarks({ id: jobId });
        } catch (error) {
          const userFacingMessage = "Failed to load job outreach benchmarks. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    getJobReportMeta: build.query<JobReportMeta, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobReportMeta;
        try {
          data = await client.getJobReportMeta({ id: jobId });
        } catch (error) {
          const userFacingMessage = "Failed to load report data. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    getJobOutboundStats: build.query<JobOutboundStats, ApiApiGetJobOutboundStatsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobOutboundStats;
        try {
          data = await client.getJobOutboundStats({ ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load job outbound stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    getJobInboundStats: build.query<JobInboundStats, ApiApiGetJobInboundStatsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobInboundStats;
        try {
          data = await client.getJobInboundStats({ ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load job inbound stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    getJobReferralsStats: build.query<JobReferralsStats, ApiApiGetJobReferralsStatsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobReferralsStats;
        try {
          data = await client.getJobReferralsStats({ ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load job referrals stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    sendJobSlackChannelInvite: build.mutation<SendJobSlackChannelInviteResponse, { jobId: string }>({
      queryFn: async ({ jobId }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: SendJobSlackChannelInviteResponse;
        try {
          data = await client.sendJobSlackChannelInvite({ id: jobId });
          if (data.channelName) {
            showErrorToast(`You are already a member of the channel: ${data.channelName}`);
          } else {
            showSuccessToast("Check the `Slack Connect’ section of Slack or your email for the invite.");
          }
        } catch (error) {
          const userFacingMessage = "Failed to send job slack channel invite. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
    }),
    syncAndListJobAtsStages: build.query<SyncAndListJobAtsStages, { jobId: string; triggerResyncOverride?: boolean }>({
      queryFn: async ({ jobId, triggerResyncOverride }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: SyncAndListJobAtsStages;
        try {
          data = await client.syncAndListJobAtsStages({ id: jobId, data: { triggerResyncOverride } });
        } catch (error) {
          const userFacingMessage = "Failed to sync and list job ATS stages. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }

        return { data };
      },
      providesTags: (result, error, args) => {
        return [{ type: JOB_ATS_STAGES, id: args.jobId }];
      },
    }),
    getJobSetupSteps: build.query<JobSetupStepsWithSetupSummaryState, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});
        const data = await client.getJobSetupSteps({ id: jobId });
        return { data };
      },
      providesTags: (result, error, jobId) => {
        return result ? [{ type: JOB_SETUP, id: jobId } as const, { type: JOB_SETUP_STEP, id: jobId } as const] : [];
      },
    }),
    getApplicationPortalJob: build.query<ApplicationPortalJob, string>({
      queryFn: async jobOrSearchId => {
        const { apiApi: client } = await getOpenApiClients({ includeToken: false });
        try {
          const data = await client.getApplicationPortalJob({ jobOrSearchId });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: APPLICATION_PORTAL_JOB, id: result.id } as const] : [];
      },
    }),
    confirmPersona: build.mutation<
      { success?: boolean },
      {
        id: string;
        data: {
          searchTemplateId: number;
        };
      }
    >({
      queryFn: async ({ id, data }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.confirmJobPersona({
            id,
            data,
          });
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, { id }) => {
        return [{ type: JOB, id }];
      },
    }),
  }),
});

export const {
  useListJobsQuery,
  useGetJobQuery,
  useDeactivateJobMutation,
  useReactivateJobMutation,
  useSnoozeJobMutation,
  useUnsnoozeJobMutation,
  useGetDeactivationStatsQuery,
  useGetBenchmarkPersonaChannelStatsQuery,
  useGetJobSetupQuery,
  useUpdateJobSetupMutation,
  useUpdateJobHiringTeamMutation,
  useUpdateJobVisibilityMutation,
  useGetJobFunnelStatsQuery,
  useGetJobOutreachStatsQuery,
  useGetJobOutreachBenchmarksQuery,
  useGetJobReportMetaQuery,
  useGetJobOutboundStatsQuery,
  useGetJobInboundStatsQuery,
  useGetJobReferralsStatsQuery,
  useGetApplicationPortalJobQuery,
  useSendJobSlackChannelInviteMutation,
  useSyncAndListJobAtsStagesQuery,
  useGetJobSetupStepsQuery,
  useListLocationOptionsQuery,
  useListCurrencyOptionsQuery,
  useConfirmPersonaMutation,
} = jobEndpoints;

export const { selectById: selectJobById, selectAll: selectAllJobs } = jobAdapter.getSelectors();
export const { removeOne: removeJobById } = jobAdapter;
