import { Stack, Box } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import HelpIconSVG from "assets/icons/help-question.svg";
import { StyledHelpIconSVG } from "components/dover/CompanySetupBasicInfo/styles";
import { FormSchema, KeyObject } from "components/dover/CompanySetupBasicInfo/types";
import { getLinkedInIdFromUrl, getLinkedInUrlFromId } from "components/dover/CompanySetupBasicInfo/utils";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { getOpenApiClients } from "services/api";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  useBulkUpsertClientDomainsMutation,
  useListClientDomainsQuery,
  useUpdateClientOnboardingMutation,
} from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { colors } from "styles/theme";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

interface CompanySetupBasicInfoProps {
  hideSaveButton?: boolean;
  onPrimaryDomainIndexChange?: (newIndex: number) => void;
}

const CompanySetupBasicInfo = ({ hideSaveButton }: CompanySetupBasicInfoProps): React.ReactElement => {
  // State
  const [newDomainKeys, setNewDomainKeys] = React.useState<KeyObject[]>([]);
  const [primaryDomainValue, setPrimaryDomainValue] = React.useState<string>("");
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  // API Hooks
  const clientId = useGetClientId();
  const { data: clientOnboarding } = useGetClientOnboardingQuery();
  const { data: clientDomains } = useListClientDomainsQuery({});
  const [updateClientOnboarding, { isLoading: isUpdatingOnboarding }] = useUpdateClientOnboardingMutation();
  const [bulkUpsertDomains, { isLoading: isUpdatingDomains }] = useBulkUpsertClientDomainsMutation();

  // Form Context
  const {
    register,
    unregister,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors: formErrors },
  } = useFormContext<FormSchema>();

  // Feature Flags
  const isAdmin = useHasRole(Role.ADMIN);
  const isClientAdmin = useHasRole(Role.CLIENT_ADMIN);
  const hasPermissionsToExportCSV = isAdmin || isClientAdmin;
  const exportCsvEnabledForClient = useFeatureFlag(FeatureFlag.ExportCandidateCSV);

  // Initialize form data and primary domain
  React.useEffect(() => {
    if (clientDomains?.length) {
      const primaryDomain = clientDomains.find(d => d.isPrimaryDomain);
      if (primaryDomain) {
        setPrimaryDomainValue(primaryDomain.domain);
      } else {
        setPrimaryDomainValue(clientDomains[0].domain);
      }
    } else {
      setNewDomainKeys([{ key: uuidv4() }]);
    }

    if (clientOnboarding?.linkedinCompanyId) {
      setValue("linkedInUrl", getLinkedInUrlFromId(clientOnboarding.linkedinCompanyId));
    }
    if (clientOnboarding?.name) {
      setValue("companyName", clientOnboarding.name);
    }
  }, [clientDomains, clientOnboarding, setValue]);

  // Handlers
  const handleAddDomain = (): void => {
    setNewDomainKeys(prev => [...prev, { key: uuidv4() }]);
  };

  const handleDeleteDomain = (index: number, key: string): void => {
    setNewDomainKeys(prev => prev.filter((_, i) => i !== index));
    unregister(`newCompanyDomains.${key}` as const);
  };

  const handleMakePrimary = async (domain: string): Promise<void> => {
    const allDomains = [
      ...(clientDomains?.map(d => d.domain) || []),
      ...Object.values(getValues("newCompanyDomains") || {}),
    ];

    try {
      await bulkUpsertDomains({
        domains: allDomains,
        primaryDomain: domain,
      }).unwrap();

      setPrimaryDomainValue(domain);
    } catch (error) {
      console.error("Failed to update primary domain:", error);
      showErrorToast("Failed to update primary domain");
    }
  };

  const handleSave = async (data: FormSchema): Promise<void> => {
    const { id: linkedinCompanyId, error: linkedInError } = getLinkedInIdFromUrl(data.linkedInUrl);
    if (linkedInError) {
      setErrors({ linkedInUrl: linkedInError });
      return;
    }

    try {
      // Get all domains including new ones
      const newDomains = data.newCompanyDomains ? Object.values(data.newCompanyDomains) : [];
      const existingDomains = clientDomains?.map(d => d.domain) || [];
      const allDomains = [...existingDomains, ...newDomains];

      // If no primary domain is set, use the first domain
      const primaryDomain = primaryDomainValue || allDomains[0] || "";

      // Save all data
      await Promise.all([
        bulkUpsertDomains({
          domains: allDomains,
          primaryDomain,
        }).unwrap(),

        updateClientOnboarding({
          id: clientId!,
          data: {
            name: data.companyName,
            linkedinCompanyId,
          },
          onboardingSection: OnboardingSection.BASIC_INFO,
        }).unwrap(),
      ]);

      setNewDomainKeys([]);
      setErrors({});
    } catch (error) {
      console.error("Failed to save:", error);
      showErrorToast("Failed to save changes");
    }
  };

  // Render helpers
  const renderDomainField = (domain: string, isPrimary: boolean): React.ReactElement => (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box width="400px">
        <TextField text={domain} startAdornment={"@"} disabled={true} />
      </Box>
      {isPrimary ? (
        <Box ml={2}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <BodySmall color={colors.grayscale.gray600}>Primary domain</BodySmall>
            <Tooltip
              title="This domain is linked from your careers page company logo and is used in candidate communications."
              placement="top"
              arrow={true}
              variant={TooltipVariant.Dark}
            >
              <span>
                <Box width="14px" height="14px">
                  <StyledHelpIconSVG src={HelpIconSVG} />
                </Box>
              </span>
            </Tooltip>
          </Stack>
        </Box>
      ) : (
        <Box ml={2}>
          <Button variant={ButtonVariant.Link} onClick={(): Promise<void> => handleMakePrimary(domain)} padding="0">
            Make primary
          </Button>
        </Box>
      )}
    </Stack>
  );

  // Sort domains to show primary first
  const sortedDomains = React.useMemo(() => {
    if (!clientDomains) return [];
    return [...clientDomains].sort((a, b) => {
      if (a.domain === primaryDomainValue) return -1;
      if (b.domain === primaryDomainValue) return 1;
      return 0;
    });
  }, [clientDomains, primaryDomainValue]);

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <TextField
          title="Company Name"
          required={true}
          errorMessage={formErrors.companyName?.message}
          error={!!formErrors.companyName}
          inputProps={{ ...register("companyName") }}
        />

        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Body weight="500">Company Domains</Body>
            <Tooltip
              title="Add the email domains associated with your company. The primary domain will be used for candidate communications."
              placement="top"
              arrow={true}
              variant={TooltipVariant.Dark}
            >
              <span>
                <StyledHelpIconSVG src={HelpIconSVG} />
              </span>
            </Tooltip>
          </Stack>

          {/* Existing domains */}
          {sortedDomains.map(domain => (
            <Stack key={domain.domain}>{renderDomainField(domain.domain, domain.domain === primaryDomainValue)}</Stack>
          ))}

          {/* New domains */}
          {newDomainKeys.map((domain, index) => (
            <Stack key={domain.key}>
              <Box width="432px">
                <TextField
                  required={true}
                  startAdornment={"@"}
                  placeholderText={"Enter your website (e.g. dover.com)"}
                  errorMessage={formErrors.newCompanyDomains?.[domain.key]?.message ?? errors[domain.key]}
                  error={!!formErrors.newCompanyDomains?.[domain.key] || !!errors[domain.key]}
                  onDelete={
                    clientDomains?.length || newDomainKeys.length > 1
                      ? (): void => handleDeleteDomain(index, domain.key)
                      : undefined
                  }
                  inputProps={{ ...register(`newCompanyDomains.${domain.key}` as const) }}
                />
              </Box>
            </Stack>
          ))}

          <Button variant={ButtonVariant.Ghost} onClick={handleAddDomain} width="fit-content" padding="6px 0">
            <Body weight="500">+Add Domain</Body>
          </Button>
        </Stack>

        <TextField
          title="Company LinkedIn Page"
          required={true}
          errorMessage={formErrors.linkedInUrl?.message ?? errors.linkedInUrl}
          error={!!formErrors.linkedInUrl || !!errors.linkedInUrl}
          placeholderText="e.g. https://www.linkedin.com/company/doverhq"
          inputProps={{ ...register("linkedInUrl") }}
        />
      </Stack>

      {/* Export CSV section */}
      {(exportCsvEnabledForClient || isAdmin) && (
        <Stack spacing={2}>
          <Body weight="500">Data Export</Body>
          <Button
            width="206px"
            variant={ButtonVariant.Secondary}
            onClick={async (): Promise<void> => {
              if (!clientOnboarding?.id) return;
              showPendingToast("Exporting your data...");
              const { apiApi } = await getOpenApiClients({});
              const response = await apiApi.exportDataAsCSV({ id: clientOnboarding.id });
              if (response.success) {
                showSuccessToast(response.message);
              } else {
                showErrorToast(response.message);
              }
            }}
            disabled={!hasPermissionsToExportCSV}
            tooltip={
              hasPermissionsToExportCSV
                ? "Export your data as a CSV file. An email will be sent to your email (~3 minutes) containing results once job is complete."
                : "Looks like you don't have permission to download your data. Contact your admin for help"
            }
          >
            Export my data as CSV
          </Button>
        </Stack>
      )}

      {/* Save button */}
      {!hideSaveButton && (
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <Button
            variant={ButtonVariant.Primary}
            onClick={handleSubmit(handleSave)}
            loading={isUpdatingOnboarding || isUpdatingDomains}
          >
            <Body color="inherit" style={{ letterSpacing: "2px" }}>
              Save
            </Body>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanySetupBasicInfo;
