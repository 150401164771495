import React from "react";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { formatDecimalToPercent } from "views/Reporting/components/ChannelTiles/utils";
import { BenchmarkSectionSpinnerBox, BenchmarkSectionText } from "views/Reporting/styles";

interface PercentCandidatesReviewedProps {
  percentTotalCandidates: number | undefined;
  isLoading?: boolean;
  showStats?: boolean;
}

export const PercentCandidatesReviewed = ({
  percentTotalCandidates,
  isLoading = false,
  showStats = true,
}: PercentCandidatesReviewedProps): React.ReactElement => {
  const formattedPercentTotalCandidates = formatDecimalToPercent(percentTotalCandidates);

  return (
    <>
      <BenchmarkSectionText>% of total candidates</BenchmarkSectionText>
      {isLoading ? (
        <BenchmarkSectionSpinnerBox>
          <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
        </BenchmarkSectionSpinnerBox>
      ) : (
        <BenchmarkSectionText>{showStats ? <>{formattedPercentTotalCandidates}</> : <>--</>}</BenchmarkSectionText>
      )}
    </>
  );
};
