import React from "react";
import { ReactSVG } from "react-svg";

import PeopleSVG from "assets/icons/people-outlined-grey.svg";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useGetJobFunnelStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";
import { StyledBox } from "views/Reporting/styles";

interface ActiveCandidatesProps {
  jobId: string;
}

export const ActiveCandidates = ({ jobId }: ActiveCandidatesProps): React.ReactElement => {
  const { data: interviewStats, isFetching: isFetchingInterviewStats } = useGetJobFunnelStatsQuery({
    id: jobId,
  });

  const activeCount = interviewStats
    ? Object.values(interviewStats.statsByStage).reduce(
        (runningTotal, stageStats) => (runningTotal += stageStats?.active || 0),
        0
      )
    : undefined;

  return (
    <StyledBox>
      {PeopleSVG && <ReactSVG src={PeopleSVG} />}
      <Spacer width={8} />
      {isFetchingInterviewStats ? (
        <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
      ) : (
        <B2_doNotUse $color={colors.grayscale.gray600}>{activeCount}</B2_doNotUse>
      )}
      <Spacer width={4} />
      <B2_doNotUse $color={colors.grayscale.gray600}>candidates active</B2_doNotUse>
    </StyledBox>
  );
};
