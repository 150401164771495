import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { useGetJobInboundStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { CandidatesReviewedStat } from "views/Reporting/components/ChannelTiles/CandidatesReviewedStat";
import { ChannelName, DateRange, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface InboundCandidatesReviewedProps {
  jobId: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
}

export const InboundCandidatesReviewed = ({
  jobId,
  dateRange,
  selectedSource,
}: InboundCandidatesReviewedProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.INBOUND);

  const { data: inboundStats, isFetching } = useGetJobInboundStatsQuery(
    showStats
      ? {
          id: jobId,
          ...dateRange,
          channelName: selectedSource?.channelName,
          candidateSource: selectedSource?.candidateSource,
        }
      : skipToken
  );

  return (
    <CandidatesReviewedStat
      numCandidatesReviewed={inboundStats?.numClientReview}
      isLoading={isFetching}
      showStats={showStats}
    />
  );
};
