import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { useGetJobInboundStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { PercentCandidatesReviewed } from "views/Reporting/components/ChannelTiles/PercentCandidatesReviewed";
import { ChannelName, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface InboundPercentCandidatesReviewedProps {
  jobId: string;
  selectedSource: SourceOptionValue;
}

export const InboundPercentCandidatesReviewed = ({
  jobId,
  selectedSource,
}: InboundPercentCandidatesReviewedProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.INBOUND);

  const { data: inboundStats, isFetching } = useGetJobInboundStatsQuery(
    showStats
      ? {
          id: jobId,
        }
      : skipToken
  );

  return (
    <PercentCandidatesReviewed
      percentTotalCandidates={inboundStats?.percentClientReview ?? undefined}
      isLoading={isFetching}
      showStats={showStats}
    />
  );
};
