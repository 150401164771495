import React, { useMemo } from "react";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetBenchmarkPersonaChannelStatsQuery } from "services/doverapi/endpoints/job";
import { ChannelName } from "services/doverapi/endpoints/job-source-settings/types";
import { colors } from "styles/theme";
import { formatDecimalToPercent } from "views/Reporting/components/ChannelTiles/utils";
import { BenchmarkSectionSpinnerBox, BenchmarkSectionText } from "views/Reporting/styles";
import { SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface PercentCandidatesReviewedBenchmarkProps {
  jobId: string;
  channelName: ChannelName;
  selectedSource: SourceOptionValue;
}

export const PercentCandidatesReviewedBenchmark = ({
  jobId,
  channelName,
  selectedSource,
}: PercentCandidatesReviewedBenchmarkProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, channelName);

  const { data: benchmarkStats, isFetching } = useGetBenchmarkPersonaChannelStatsQuery(jobId);

  const candidatesReviewedBenchmark = useMemo(() => {
    let stat = "";

    switch (channelName) {
      case ChannelName.INBOUND:
        stat = formatDecimalToPercent(benchmarkStats?.inboundPercentage);
        break;
      case ChannelName.OUTBOUND:
        stat = formatDecimalToPercent(benchmarkStats?.outboundPercentage);
        break;
      case ChannelName.REFERRALS:
        stat = formatDecimalToPercent(benchmarkStats?.referralsPercentage);
        break;
      default:
        break;
    }

    return stat;
  }, [channelName, benchmarkStats]);

  return (
    <>
      <BenchmarkSectionText $color={colors.grayscale.gray500}>Benchmark</BenchmarkSectionText>
      {isFetching ? (
        <BenchmarkSectionSpinnerBox>
          <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
        </BenchmarkSectionSpinnerBox>
      ) : (
        <BenchmarkSectionText $color={colors.grayscale.gray500}>
          {showStats ? <>{candidatesReviewedBenchmark}</> : <>--</>}
        </BenchmarkSectionText>
      )}
    </>
  );
};
