import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { useGetJobReferralsStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { PercentCandidatesReviewed } from "views/Reporting/components/ChannelTiles/PercentCandidatesReviewed";
import { ChannelName, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface ReferralsPercentCandidatesReviewedProps {
  jobId: string;
  selectedSource: SourceOptionValue;
}

export const ReferralsPercentCandidatesReviewed = ({
  jobId,
  selectedSource,
}: ReferralsPercentCandidatesReviewedProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.REFERRALS);

  const { data: referralsStats, isFetching } = useGetJobReferralsStatsQuery(
    showStats
      ? {
          id: jobId,
        }
      : skipToken
  );

  return (
    <PercentCandidatesReviewed
      percentTotalCandidates={referralsStats?.percentClientReview ?? undefined}
      isLoading={isFetching}
      showStats={showStats}
    />
  );
};
