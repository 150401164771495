import { MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

import { Tooltip } from "components/library/Tooltip";
import { B2_doNotUse } from "styles/typography";
import { StyledSelect } from "views/Reporting/styles";
import { CandidateDemographic, ChannelName, SourceOption } from "views/Reporting/types";

interface DemographicDropdownSelectorProps {
  value: CandidateDemographic | undefined;
  onChange: (demographic: CandidateDemographic) => void;
  selectedSource: SourceOption;
}

export const DemographicDropdownSelector = ({
  value,
  onChange,
  selectedSource,
}: DemographicDropdownSelectorProps): React.ReactElement => {
  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    onChange(event.target.value as CandidateDemographic);
  };

  const disabled = selectedSource.value.channelName !== ChannelName.OUTBOUND;
  const tooltipText = disabled
    ? `You can only filter on demographics when an outbound source is selected in the Channels above.`
    : "";

  return (
    <Tooltip title={tooltipText}>
      <StyledSelect
        variant="outlined"
        name="demographic"
        label="Demographic"
        value={value}
        onChange={handleChange}
        disabled={disabled}
        displayEmpty
        size="small"
      >
        <MenuItem value={CandidateDemographic.All}>
          <B2_doNotUse>All</B2_doNotUse>
        </MenuItem>
        <MenuItem value={CandidateDemographic.Diverse}>
          <B2_doNotUse>Female / URM</B2_doNotUse>
        </MenuItem>
      </StyledSelect>
    </Tooltip>
  );
};
