import { Box } from "@mui/material";
import React from "react";

import { Spacer } from "components/Spacer";
import { ChannelName } from "services/doverapi/endpoints/job-source-settings/types";
import { CardHeader } from "styles/typography";
import { InboundCandidatesReviewed } from "views/Reporting/components/ChannelTiles/Inbound/InboundCandidatesReviewed";
import { InboundPercentCandidatesReviewed } from "views/Reporting/components/ChannelTiles/Inbound/InboundPercentCandidatesReviewed";
import { PercentCandidatesReviewedBenchmark } from "views/Reporting/components/ChannelTiles/PercentCandidatesReviewedBenchmark";
import { DateRange, SourceOptionValue } from "views/Reporting/types";

interface InboundTopLevelChannelStatProps {
  jobId: string;
  totalCandidateSentToSlack: number | undefined;
  benchmarkPercentage?: number;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
}

export const InboundTopLevelChannelStat = ({
  jobId,
  totalCandidateSentToSlack,
  benchmarkPercentage,
  dateRange,
  selectedSource,
}: InboundTopLevelChannelStatProps): React.ReactElement => {
  const showChannelCandidatePercentStats = !!totalCandidateSentToSlack && !!benchmarkPercentage;
  return (
    <div>
      <CardHeader bold>{ChannelName.INBOUND}</CardHeader>
      <Spacer height={4} />
      <InboundCandidatesReviewed jobId={jobId} dateRange={dateRange} selectedSource={selectedSource} />
      {showChannelCandidatePercentStats && (
        <Box marginTop="16px">
          <Box display="flex" justifyContent="space-between">
            <InboundPercentCandidatesReviewed jobId={jobId} selectedSource={selectedSource} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <PercentCandidatesReviewedBenchmark
              jobId={jobId}
              channelName={ChannelName.INBOUND}
              selectedSource={selectedSource}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};
