import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetJobReportMetaQuery } from "services/doverapi/endpoints/job/endpoints";
import { B2_doNotUse, InternalLink } from "styles/typography";

interface PotentialReferralsCandidatesProps {
  jobId: string;
  showStats?: boolean;
}

export const PotentialReferralsCandidates = ({
  jobId,
  showStats = true,
}: PotentialReferralsCandidatesProps): React.ReactElement => {
  const { data: jobReportMetaData, isFetching } = useGetJobReportMetaQuery(jobId ? jobId : skipToken);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <B2_doNotUse>
        {isFetching ? (
          <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
        ) : (
          <strong>
            {jobReportMetaData?.numPotentialReferralCandidates === undefined || !showStats ? (
              <>--</>
            ) : (
              <>{jobReportMetaData?.numPotentialReferralCandidates}</>
            )}
          </strong>
        )}{" "}
        unreviewed {jobReportMetaData?.numPotentialReferralCandidates === 1 ? "candidate" : "candidates"}
      </B2_doNotUse>

      {!!jobReportMetaData?.numPotentialReferralCandidates && showStats && (
        <InternalLink to={`/referrals/review/needs-review?jobId=${jobId}`} target="_blank" rel="noopener noreferrer">
          <B2_doNotUse>Review</B2_doNotUse>
        </InternalLink>
      )}
    </Box>
  );
};
