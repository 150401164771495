import { Box } from "@mui/material";
import React from "react";

import { Spacer } from "components/Spacer";
import { ChannelName } from "services/doverapi/endpoints/job-source-settings/types";
import { CardHeader } from "styles/typography";
import { OutboundCandidatesReviewed } from "views/Reporting/components/ChannelTiles/Outbound/OutboundCandidatesReviewed";
import { OutboundPercentCandidatesReviewed } from "views/Reporting/components/ChannelTiles/Outbound/OutboundPercentCandidatesReviewed";
import { PercentCandidatesReviewedBenchmark } from "views/Reporting/components/ChannelTiles/PercentCandidatesReviewedBenchmark";
import { CandidateDemographic, DateRange, SourceOptionValue } from "views/Reporting/types";

interface OutboundTopLevelChannelStatProps {
  jobId: string;
  totalCandidateSentToSlack: number | undefined;
  benchmarkPercentage?: number;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
  selectedDemographic: CandidateDemographic;
}

export const OutboundTopLevelChannelStat = ({
  jobId,
  totalCandidateSentToSlack,
  benchmarkPercentage,
  dateRange,
  selectedSource,
  selectedDemographic,
}: OutboundTopLevelChannelStatProps): React.ReactElement => {
  const showChannelCandidatePercentStats = !!totalCandidateSentToSlack && !!benchmarkPercentage;
  return (
    <div>
      <CardHeader bold>{ChannelName.OUTBOUND}</CardHeader>
      <Spacer height={4} />
      <OutboundCandidatesReviewed
        jobId={jobId}
        dateRange={dateRange}
        selectedSource={selectedSource}
        selectedDemographic={selectedDemographic}
      />
      {showChannelCandidatePercentStats && (
        <Box marginTop="16px">
          <Box display="flex" justifyContent="space-between">
            <OutboundPercentCandidatesReviewed jobId={jobId} selectedSource={selectedSource} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <PercentCandidatesReviewedBenchmark
              jobId={jobId}
              channelName={ChannelName.OUTBOUND}
              selectedSource={selectedSource}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};
