import { Box } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import AlertSVG from "assets/icons/alert-orange.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useListJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/jobCandidateSourceSetting";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { JobCandidateSourceSetting } from "services/openapi";
import { PotentialInboundCandidates } from "views/Reporting/components/ChannelTiles/Inbound/PotentialInboundCandidates";
import { ChannelName, DateRange, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface InboundChannelDetailProps {
  jobId: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
}

export const InboundChannelDetail = ({
  jobId,
  dateRange,
  selectedSource,
}: InboundChannelDetailProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.INBOUND);
  const {
    activeInboundSourceLabels,
    numActiveInboundSources,
    isFetchingActiveInboundSources,
  } = useListJobCandidateSourceSettingsQuery(
    { job: jobId, direction: "INBOUND", state: "ACTIVE" },
    {
      selectFromResult: ({ data, isFetching }) => {
        const jobCandidateSourceSettings: JobCandidateSourceSetting[] = listAllEntities(data);
        const activeInboundSourceLabels = jobCandidateSourceSettings
          .map((jcss): string | undefined => jcss.candidateSource?.label)
          .filter((label): boolean => !!label);

        return {
          activeInboundSourceLabels,
          numActiveInboundSources: activeInboundSourceLabels.length,
          isFetchingActiveInboundSources: isFetching,
        };
      },
    }
  );

  if (isFetchingActiveInboundSources) {
    return <DoverLoadingSpinner />;
  }

  const sourceSetupUrl = `/job/${jobId}/setup/channels`;

  if (numActiveInboundSources > 0) {
    const sourcesString = activeInboundSourceLabels.join(", ");
    return (
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <PotentialInboundCandidates jobId={jobId} dateRange={dateRange} selectedSource={selectedSource} />
        <Spacer height={24} />
        <BodySmall weight="600">
          {showStats ? <>{numActiveInboundSources}</> : <>--</>} active source
          {numActiveInboundSources > 1 ? "s" : ""}
        </BodySmall>
        <Spacer height={8} />
        <BodySmall>{showStats ? <>{sourcesString}</> : <>--</>}</BodySmall>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" alignItems="flex-end">
        {AlertSVG && <ReactSVG src={AlertSVG} />}
        <Spacer width={8} />
        <BodySmall weight="600">No sources set up</BodySmall>
      </Box>
      <Spacer height={24} />
      <BodySmall centered>Set up inbound sources to increase your candidate flow.</BodySmall>
      <Spacer height={24} />
      <Button
        variant={ButtonVariant.Secondary}
        onClick={(): void => {
          window.open(sourceSetupUrl, "_blank", "noopener noreferrer");
        }}
      >
        <BodySmall>Set Up Sources</BodySmall>
      </Button>
    </Box>
  );
};
