import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { ReactSVG } from "react-svg";

import AlertSVG from "assets/icons/alert.svg";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetJobOutreachStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { B2_doNotUse } from "styles/typography";
import { renderOutboundStat } from "views/Reporting/components/ChannelTiles/utils";
import { CandidateDemographic, ChannelName, DateRange, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant, isDateFilterRangeLessThanTwoWeeks } from "views/Reporting/utils";

interface OutreachStatProps {
  jobId: string;
  outreachStatsKey: "responseRate" | "interestedRate";
  label: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
  selectedDemographic: CandidateDemographic;
}

export const OutreachStat = ({
  jobId,
  outreachStatsKey,
  label,
  dateRange,
  selectedSource,
  selectedDemographic,
}: OutreachStatProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.OUTBOUND);

  const { data: outreachStats, isFetching } = useGetJobOutreachStatsQuery(
    showStats
      ? {
          id: jobId,
          ...dateRange,
          channelName: selectedSource?.channelName,
          candidateSource: selectedSource?.candidateSource,
          demographic: selectedDemographic,
        }
      : skipToken
  );

  const showWarning: boolean = isDateFilterRangeLessThanTwoWeeks(dateRange);

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <B2_doNotUse>{label}</B2_doNotUse>
      {isFetching ? (
        <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
      ) : (
        <>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {showWarning && (
              <Tooltip
                variant={TooltipVariant.Dark}
                title={"Insufficient data - time period is < 2 weeks"}
                placement="top"
              >
                <div>
                  <ReactSVG style={{ position: "relative", bottom: "2px" }} src={AlertSVG} />
                </div>
              </Tooltip>
            )}
            <B2_doNotUse>{showStats ? renderOutboundStat(outreachStats!, outreachStatsKey) : <>--</>}</B2_doNotUse>
          </Stack>
        </>
      )}
    </Box>
  );
};
