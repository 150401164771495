import { isNil, some } from "lodash";

import { JobOutreachStats, JobOutreachBenchmarks } from "services/openapi";

export function renderOutboundStat(stats: JobOutreachStats, statName: "responseRate" | "interestedRate"): string {
  const statValue = stats && stats[statName];

  if (!statValue) {
    return `--`;
  }

  const percentage = (statValue * 100).toFixed(1);
  return `${percentage}%`;
}

export function renderOutboundBenchmark(
  benchmarks: JobOutreachBenchmarks | undefined,
  benchmark: "responseRate" | "interestedRate"
): string {
  const benchmarkValue = benchmarks && benchmarks[benchmark];
  const hasAllBenchmarks = benchmarks !== null && !some(benchmarks, val => val === null);

  if (!hasAllBenchmarks || isNil(benchmarkValue)) {
    return "--";
  }

  return `${(benchmarkValue * 100).toFixed(1)}%`;
}

export function formatDecimalToPercent(decimalNum: number | undefined): string {
  return Math.round((decimalNum || 0) * 100) + "%";
}
