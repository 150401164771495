import { useMemo } from "react";

import { useListCandidateSourcesQuery } from "services/doverapi/endpoints/candidateSource";
import { useListJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/jobCandidateSourceSetting";
import { getEntityById, listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CandidateSource } from "services/openapi";

export const useListActiveCandidateSources = (
  jobId: string
): { data: CandidateSource[] | undefined; isFetching: boolean } => {
  const {
    data: jobCandidateSourceSettings,
    isFetching: isFetchingJobCandidateSourceSettings,
  } = useListJobCandidateSourceSettingsQuery({ job: jobId, state: "ACTIVE" });

  const { data: candidateSources, isFetching: isFetchingCandidateSources } = useListCandidateSourcesQuery({
    jobId,
  });

  // Memoize for true hook behavior.
  // In other words, we should return a referentially equivalent object if jobId doesn't change and RTK queries don't update.
  return useMemo(() => {
    if (isFetchingJobCandidateSourceSettings || isFetchingCandidateSources) {
      return { data: undefined, isFetching: true };
    }

    const activeCandidateSources: CandidateSource[] = [];

    listAllEntities(jobCandidateSourceSettings).forEach(jcss => {
      const candidateSource = getEntityById(candidateSources, jcss.candidateSource?.id);
      if (jcss.state === "ACTIVE" && candidateSource) {
        activeCandidateSources.push(candidateSource);
      }
    });

    return { data: activeCandidateSources, isFetching: false };
  }, [isFetchingJobCandidateSourceSettings, isFetchingCandidateSources, jobCandidateSourceSettings, candidateSources]);
};
