/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CareersPageDisplayStat,
    CareersPageDisplayStatFromJSON,
    CareersPageDisplayStatFromJSONTyped,
    CareersPageDisplayStatToJSON,
    ClientSocialLink,
    ClientSocialLinkFromJSON,
    ClientSocialLinkFromJSONTyped,
    ClientSocialLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientOnboarding
 */
export interface ClientOnboarding {
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    setupBasicInfoState?: ClientOnboardingSetupBasicInfoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    linkedinCompanyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    emailProvider?: ClientOnboardingEmailProviderEnum;
    /**
     * Indicates what primary messaging provider the client uses
     * @type {string}
     * @memberof ClientOnboarding
     */
    messagingProvider?: ClientOnboardingMessagingProviderEnum;
    /**
     * Indicates the non-Dover supported messaging provider that a client uses
     * @type {string}
     * @memberof ClientOnboarding
     */
    messagingProviderOther?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientOnboarding
     */
    readonly onboardingCallRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    setupAtsState?: ClientOnboardingSetupAtsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    atsType?: ClientOnboardingAtsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    greenhouseApiKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    readonly greenhouseCredsLastValidatedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientOnboarding
     */
    readonly greenhouseCredsAreValid?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    greenhouseSubdomain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    ashbyApiKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    readonly ashbyCredsLastValidatedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientOnboarding
     */
    readonly ashbyCredsAreValid?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientOnboarding
     */
    readonly leverCredsAreValid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    setupBrandingState?: ClientOnboardingSetupBrandingStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientOnboarding
     */
    excludedCompanies?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    setupExclusionsState?: ClientOnboardingSetupExclusionsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    setupCompanyBiographyState?: ClientOnboardingSetupCompanyBiographyStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    careersPageImageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    readonly careersPageImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    careersPageInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    careersPageLogoRedirectUrl?: string | null;
    /**
     * 
     * @type {Array<CareersPageDisplayStat>}
     * @memberof ClientOnboarding
     */
    careersPageDisplayStats?: Array<CareersPageDisplayStat>;
    /**
     * 
     * @type {Array<ClientSocialLink>}
     * @memberof ClientOnboarding
     */
    careersPageSocialLinks?: Array<ClientSocialLink>;
    /**
     * 
     * @type {string}
     * @memberof ClientOnboarding
     */
    setupEmailState?: ClientOnboardingSetupEmailStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ClientOnboarding
     */
    readonly hasDoverManagedDomainSetUp?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ClientOnboardingSetupBasicInfoStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingEmailProviderEnum {
    Gmail = 'GMAIL',
    Outlook = 'OUTLOOK',
    Other = 'OTHER'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingMessagingProviderEnum {
    Slack = 'SLACK',
    Other = 'OTHER'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingSetupAtsStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingAtsTypeEnum {
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Ashby = 'ASHBY',
    GoogleHire = 'GOOGLE_HIRE',
    NoAts = 'NO_ATS',
    Other = 'OTHER',
    NotRequired = 'NOT_REQUIRED'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingSetupBrandingStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingSetupExclusionsStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingSetupCompanyBiographyStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum ClientOnboardingSetupEmailStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function ClientOnboardingFromJSON(json: any): ClientOnboarding {
    return ClientOnboardingFromJSONTyped(json, false);
}

export function ClientOnboardingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboarding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'setupBasicInfoState': !exists(json, 'setup_basic_info_state') ? undefined : json['setup_basic_info_state'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'linkedinCompanyId': !exists(json, 'linkedin_company_id') ? undefined : json['linkedin_company_id'],
        'emailProvider': !exists(json, 'email_provider') ? undefined : json['email_provider'],
        'messagingProvider': !exists(json, 'messaging_provider') ? undefined : json['messaging_provider'],
        'messagingProviderOther': !exists(json, 'messaging_provider_other') ? undefined : json['messaging_provider_other'],
        'onboardingCallRequired': !exists(json, 'onboarding_call_required') ? undefined : json['onboarding_call_required'],
        'setupAtsState': !exists(json, 'setup_ats_state') ? undefined : json['setup_ats_state'],
        'atsType': !exists(json, 'ats_type') ? undefined : json['ats_type'],
        'greenhouseApiKey': !exists(json, 'greenhouse_api_key') ? undefined : json['greenhouse_api_key'],
        'greenhouseCredsLastValidatedAt': !exists(json, 'greenhouse_creds_last_validated_at') ? undefined : json['greenhouse_creds_last_validated_at'],
        'greenhouseCredsAreValid': !exists(json, 'greenhouse_creds_are_valid') ? undefined : json['greenhouse_creds_are_valid'],
        'greenhouseSubdomain': !exists(json, 'greenhouse_subdomain') ? undefined : json['greenhouse_subdomain'],
        'ashbyApiKey': !exists(json, 'ashby_api_key') ? undefined : json['ashby_api_key'],
        'ashbyCredsLastValidatedAt': !exists(json, 'ashby_creds_last_validated_at') ? undefined : json['ashby_creds_last_validated_at'],
        'ashbyCredsAreValid': !exists(json, 'ashby_creds_are_valid') ? undefined : json['ashby_creds_are_valid'],
        'leverCredsAreValid': !exists(json, 'lever_creds_are_valid') ? undefined : json['lever_creds_are_valid'],
        'logoUrl': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'setupBrandingState': !exists(json, 'setup_branding_state') ? undefined : json['setup_branding_state'],
        'excludedCompanies': !exists(json, 'excluded_companies') ? undefined : json['excluded_companies'],
        'setupExclusionsState': !exists(json, 'setup_exclusions_state') ? undefined : json['setup_exclusions_state'],
        'setupCompanyBiographyState': !exists(json, 'setup_company_biography_state') ? undefined : json['setup_company_biography_state'],
        'careersPageImageId': !exists(json, 'careers_page_image_id') ? undefined : json['careers_page_image_id'],
        'careersPageImageUrl': !exists(json, 'careers_page_image_url') ? undefined : json['careers_page_image_url'],
        'careersPageInfo': !exists(json, 'careers_page_info') ? undefined : json['careers_page_info'],
        'careersPageLogoRedirectUrl': !exists(json, 'careers_page_logo_redirect_url') ? undefined : json['careers_page_logo_redirect_url'],
        'careersPageDisplayStats': !exists(json, 'careers_page_display_stats') ? undefined : ((json['careers_page_display_stats'] as Array<any>).map(CareersPageDisplayStatFromJSON)),
        'careersPageSocialLinks': !exists(json, 'careers_page_social_links') ? undefined : ((json['careers_page_social_links'] as Array<any>).map(ClientSocialLinkFromJSON)),
        'setupEmailState': !exists(json, 'setup_email_state') ? undefined : json['setup_email_state'],
        'hasDoverManagedDomainSetUp': !exists(json, 'has_dover_managed_domain_set_up') ? undefined : json['has_dover_managed_domain_set_up'],
    };
}

export function ClientOnboardingToJSON(value?: ClientOnboarding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'setup_basic_info_state': value.setupBasicInfoState,
        'name': value.name,
        'linkedin_company_id': value.linkedinCompanyId,
        'email_provider': value.emailProvider,
        'messaging_provider': value.messagingProvider,
        'messaging_provider_other': value.messagingProviderOther,
        'setup_ats_state': value.setupAtsState,
        'ats_type': value.atsType,
        'greenhouse_api_key': value.greenhouseApiKey,
        'greenhouse_subdomain': value.greenhouseSubdomain,
        'ashby_api_key': value.ashbyApiKey,
        'logo_url': value.logoUrl,
        'setup_branding_state': value.setupBrandingState,
        'excluded_companies': value.excludedCompanies,
        'setup_exclusions_state': value.setupExclusionsState,
        'setup_company_biography_state': value.setupCompanyBiographyState,
        'careers_page_image_id': value.careersPageImageId,
        'careers_page_info': value.careersPageInfo,
        'careers_page_logo_redirect_url': value.careersPageLogoRedirectUrl,
        'careers_page_display_stats': value.careersPageDisplayStats === undefined ? undefined : ((value.careersPageDisplayStats as Array<any>).map(CareersPageDisplayStatToJSON)),
        'careers_page_social_links': value.careersPageSocialLinks === undefined ? undefined : ((value.careersPageSocialLinks as Array<any>).map(ClientSocialLinkToJSON)),
        'setup_email_state': value.setupEmailState,
    };
}


