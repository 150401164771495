import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetJobReferralsStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { B2_doNotUse, InternalLink } from "styles/typography";
import { DateRange } from "views/Reporting/types";

interface ReferralsContactedProps {
  jobId: string;
  dateRange: DateRange;
  showStats?: boolean;
}

export const ReferralsContacted = ({
  jobId,
  dateRange,
  showStats = true,
}: ReferralsContactedProps): React.ReactElement => {
  const { data: referralsStats, isFetching: isFetchingReferralsStats } = useGetJobReferralsStatsQuery(
    showStats
      ? {
          id: jobId,
          ...dateRange,
        }
      : skipToken
  );

  const referralsReviewUrl = `/referrals/review/contacted?jobId=${jobId}`;

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <B2_doNotUse>
          {isFetchingReferralsStats ? (
            <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
          ) : (
            <>
              <strong>
                {referralsStats?.numContactedReferrals === undefined || !showStats ? (
                  <>--</>
                ) : (
                  <>{referralsStats?.numContactedReferrals}</>
                )}
              </strong>{" "}
              {referralsStats?.numContactedReferrals === 1 ? "candidate" : "candidates"} contacted
            </>
          )}
        </B2_doNotUse>

        {!!(referralsStats?.numContactedReferrals && referralsStats?.numContactedReferrals > 0) && showStats && (
          <InternalLink to={referralsReviewUrl} target="_blank" rel="noopener noreferrer">
            <B2_doNotUse>View</B2_doNotUse>
          </InternalLink>
        )}
      </Box>
    </>
  );
};
