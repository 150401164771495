// interface used to represent date filter values
export interface DateRange {
  start?: string;
  end?: string;
}

// channel filters
export enum ChannelName {
  OUTBOUND = "OUTBOUND",
  INBOUND = "INBOUND",
  REFERRALS = "REFERRAL",
}

// interface used to represent channel filter values (sent to endpoint)
export interface SourceOptionValue {
  channelName: ChannelName | undefined;
  candidateSource: string[] | undefined;
}

// interface used for representing channels in dropdown
export interface SourceOption {
  label: string;
  value: SourceOptionValue;
}

// demographic filters
export enum CandidateDemographic {
  All = "",
  Diverse = "DIVERSE",
}
