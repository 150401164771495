import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { get, sum } from "lodash";
import React, { useState } from "react";
import { UncontrolledPopover, ListGroupItem, ListGroup } from "reactstrap";

import { BodySmall } from "components/library/typography";
import { DashboardJob, JobInterviewStageStats, JobInterviewSubStageStats } from "services/openapi";
import {
  ChildRow,
  ChildTableHeader,
  StyledLink,
  StyledDrilldown,
  ExpandableTableHeader,
  FlexSpan,
} from "views/Reporting/styles";
import { renderPassThroughRate } from "views/Reporting/utils";

export const StageRow: React.FC<{
  allStats: JobInterviewStageStats[];
  stageStats: JobInterviewStageStats;
  job: DashboardJob;
  showPercentages?: boolean;
}> = ({ allStats, stageStats, job, showPercentages }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!stageStats) {
    return <></>;
  }

  const isExpandable = stageStats?.subgroups.length > 0;

  const lostSubComponents = [stageStats?.rejected, stageStats?.withdrew, stageStats?.snoozed];
  let lost: number | null;
  if (lostSubComponents.every(subComponent => subComponent !== undefined && subComponent !== null)) {
    lost = sum(lostSubComponents);
  } else {
    lost = null;
  }

  const renderStat = (stat: string): string => {
    const statValue = get(stageStats, stat);
    return statValue === null ? "0" : statValue;
  };

  const renderActiveCell = (value: string | number): React.ReactElement => {
    if (value === null) {
      return <>{"—"}</>;
    }
    if (value == "0" || value === 0) {
      return <>{value}</>;
    }

    const toLink =
      stageStats.hpsId !== "HIRED"
        ? `/job/${job.id}/candidates?hpsId=${stageStats.hpsId}&status=ACTIVE`
        : `/job/${job.id}/candidates?status=ACTIVE`;
    return (
      <StyledLink to={toLink} target="_blank" rel="noopener noreferrer">
        {value}
      </StyledLink>
    );
  };

  const renderLostListItem = (name: string, value: number): React.ReactElement => {
    const label = name.charAt(0).toUpperCase() + name.slice(1);
    if (value === 0) {
      return (
        <ListGroupItem>
          {label}
          {": "}
          {value}
        </ListGroupItem>
      );
    }

    const toLink =
      stageStats.hpsId !== "HIRED"
        ? `/job/${job.id}/candidates?hpsId=${stageStats.hpsId}&status=${label.toUpperCase()}`
        : `/job/${job.id}/candidates?status=${label.toUpperCase()}`;

    return (
      <StyledLink to={toLink} target="_blank" rel="noopener noreferrer">
        <ListGroupItem>
          {label}
          {": "}
          {value}
        </ListGroupItem>
      </StyledLink>
    );
  };

  const renderLostCell = (
    totalLost: number,
    numRejected?: number,
    numWithdrew?: number,
    numSnoozed?: number
  ): React.ReactElement => {
    const rejected = numRejected !== undefined ? numRejected : parseInt(renderStat("rejected"));
    const withdrew = numWithdrew !== undefined ? numWithdrew : parseInt(renderStat("withdrew"));
    const snoozed = numSnoozed !== undefined ? numSnoozed : parseInt(renderStat("snoozed"));

    // need to put "id" in front because the id can't start with a number
    const triggerId = "id" + stageStats.hpsId.replace(/-/g, "");

    return (
      <span>
        <StyledDrilldown href="#" id={triggerId} onClick={(e): void => e.preventDefault()}>
          <span>{totalLost}</span>
          <ArrowRightIcon style={{ color: "#3F51B5", marginRight: "-8px" }} />
        </StyledDrilldown>
        <UncontrolledPopover trigger="hover" placement="right" target={triggerId}>
          <ListGroup>
            {renderLostListItem("rejected", rejected)}
            {renderLostListItem("withdrew", withdrew)}
            {renderLostListItem("snoozed", snoozed)}
          </ListGroup>
        </UncontrolledPopover>
      </span>
    );
  };

  return (
    <>
      <tr key={stageStats.hpsId}>
        <ExpandableTableHeader onClick={(): void => setIsExpanded(!isExpanded)}>
          <FlexSpan>
            {stageStats.hpsName}
            {isExpandable && (
              <span>
                {!isExpanded ? (
                  <AddIcon style={{ cursor: "pointer", color: "#3F51B5" }} />
                ) : (
                  <RemoveIcon style={{ cursor: "pointer", color: "#3F51B5" }} />
                )}
              </span>
            )}
          </FlexSpan>
        </ExpandableTableHeader>
        <td>
          {renderStat("total")}
          {showPercentages && <BodySmall>{renderPassThroughRate(allStats, stageStats)}</BodySmall>}
        </td>
        <td>{renderActiveCell(renderStat("active"))}</td>
        <td>{lost !== null && lost > 0 ? renderLostCell(lost) : "0"}</td>
      </tr>
      {isExpandable && isExpanded && (
        <>
          {stageStats.subgroups.map(
            (subgroup: JobInterviewSubStageStats): React.ReactNode => {
              const { name, label, total, active, rejected, withdrew, snoozed } = subgroup;
              const totalLost = rejected + withdrew + snoozed;
              return (
                <ChildRow key={name}>
                  <ChildTableHeader scope="row">{label}</ChildTableHeader>
                  <td>{total}</td>
                  <td>{renderActiveCell(active)}</td>
                  <td>
                    {totalLost !== null && totalLost > 0 ? renderLostCell(totalLost, rejected, withdrew, snoozed) : "0"}
                  </td>
                </ChildRow>
              );
            }
          )}
        </>
      )}
    </>
  );
};
