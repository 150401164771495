import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import {
  useGetBenchmarkPersonaChannelStatsQuery,
  useGetJobInboundStatsQuery,
  useGetJobOutboundStatsQuery,
  useGetJobReferralsStatsQuery,
} from "services/doverapi/endpoints/job/endpoints";
import { theme } from "styles/theme";
import { InboundTopLevelChannelStat } from "views/Reporting/components/ChannelTiles/Inbound/InboundTopLevelChannelStat";
import { InboundChannelDetail } from "views/Reporting/components/ChannelTiles/InboundChannelDetail";
import { OutboundTopLevelChannelStat } from "views/Reporting/components/ChannelTiles/Outbound/OutboundTopLevelChannelStat";
import { OutboundChannelDetail } from "views/Reporting/components/ChannelTiles/OutboundChannelDetail";
import { ReferralsTopLevelChannelStat } from "views/Reporting/components/ChannelTiles/Referrals/ReferralsTopLevelChannelStat";
import { ReferralsChannelDetail } from "views/Reporting/components/ChannelTiles/ReferralsChannelDetail";
import { ReportBlock, StyledHeader, StyledTable, TableHeader } from "views/Reporting/styles";
import { CandidateDemographic, ChannelName, DateRange, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface StyledTableHeaderProps {
  overlay: boolean;
}
const StyledTableHeader = styled(TableHeader)`
  background-color: ${theme.backgrounds.gray};
  padding: 24px 0px 24px 24px !important;
  opacity: ${(props: StyledTableHeaderProps): any => (props.overlay ? 1 : 0.3)};
`;

const StyledTableData = styled.td`
  padding-left: 24px !important;
  opacity: ${(props: StyledTableHeaderProps): any => (props.overlay ? 1 : 0.3)};
`;

interface ChannelTilesProps {
  jobId: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
  selectedDemographic: CandidateDemographic;
}

export const ChannelTiles: React.FC<ChannelTilesProps> = ({
  jobId,
  dateRange,
  selectedSource,
  selectedDemographic,
}) => {
  const isInboundChannelRelevant = isChannelRelevant(selectedSource, ChannelName.INBOUND);
  const isOutboundChannelRelevant = isChannelRelevant(selectedSource, ChannelName.OUTBOUND);
  const isReferralsChannelRelevant = isChannelRelevant(selectedSource, ChannelName.REFERRALS);

  const { data: benchmarkStats } = useGetBenchmarkPersonaChannelStatsQuery(jobId);

  const { data: inboundStats } = useGetJobInboundStatsQuery(
    isInboundChannelRelevant
      ? {
          id: jobId,
          ...dateRange,
          channelName: selectedSource?.channelName,
          candidateSource: selectedSource?.candidateSource,
        }
      : skipToken
  );

  const { data: outboundStats } = useGetJobOutboundStatsQuery(
    isOutboundChannelRelevant
      ? {
          id: jobId,
          start: dateRange.start,
          ...dateRange,
          channelName: selectedSource?.channelName,
          candidateSource: selectedSource?.candidateSource,
          demographic: selectedDemographic,
        }
      : skipToken
  );

  const { data: referralsStats } = useGetJobReferralsStatsQuery(
    isReferralsChannelRelevant
      ? {
          id: jobId,
          ...dateRange,
        }
      : skipToken
  );

  const totalCandidateSentToSlack =
    selectedSource?.channelName === undefined
      ? (inboundStats?.percentClientReview || 0) +
        (outboundStats?.percentClientReview || 0) +
        (referralsStats?.percentClientReview || 0)
      : undefined;

  return (
    <ReportBlock>
      <StyledHeader>Candidates Sent to Slack</StyledHeader>
      <Spacer height={20} />
      <StyledTable responsive={true} bordered>
        <thead>
          <tr>
            <StyledTableHeader scope="col" overlay={isInboundChannelRelevant}>
              <InboundTopLevelChannelStat
                jobId={jobId}
                totalCandidateSentToSlack={totalCandidateSentToSlack}
                benchmarkPercentage={benchmarkStats?.outboundPercentage}
                dateRange={dateRange}
                selectedSource={selectedSource}
              />
            </StyledTableHeader>
            <StyledTableHeader scope="col" overlay={isOutboundChannelRelevant}>
              <OutboundTopLevelChannelStat
                jobId={jobId}
                totalCandidateSentToSlack={totalCandidateSentToSlack}
                benchmarkPercentage={benchmarkStats?.outboundPercentage}
                dateRange={dateRange}
                selectedSource={selectedSource}
                selectedDemographic={selectedDemographic}
              />
            </StyledTableHeader>
            <StyledTableHeader scope="col" overlay={isReferralsChannelRelevant}>
              <ReferralsTopLevelChannelStat
                jobId={jobId}
                totalCandidateSentToSlack={totalCandidateSentToSlack}
                benchmarkPercentage={benchmarkStats?.referralsPercentage}
                dateRange={dateRange}
                selectedSource={selectedSource}
              />
            </StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <StyledTableData overlay={isInboundChannelRelevant}>
              <InboundChannelDetail jobId={jobId} dateRange={dateRange} selectedSource={selectedSource} />
            </StyledTableData>
            <StyledTableData overlay={isOutboundChannelRelevant}>
              <OutboundChannelDetail
                jobId={jobId}
                dateRange={dateRange}
                selectedSource={selectedSource}
                selectedDemographic={selectedDemographic}
              />
            </StyledTableData>
            <StyledTableData overlay={isReferralsChannelRelevant}>
              <ReferralsChannelDetail jobId={jobId} dateRange={dateRange} selectedSource={selectedSource} />
            </StyledTableData>
          </tr>
        </tbody>
      </StyledTable>
    </ReportBlock>
  );
};
