import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";

import { Centered } from "components/Centered";
import { LoadingRelative } from "components/HotLoading";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useOpenApiClients } from "hooks/openApiClients";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useGetClientOnboardingQuery, useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { metabaseReportingUrl } from "views/Reporting/constants";

const metabaseLeverScopes = ["feedback:read:admin", "offers:read:admin", "requisitions:read:admin"];

const bannerHeight = "60px";

const GREENHOUSE_AUTH_BANNER_OPEN = "GreenhouseAuthBannerOpen";

const ReAuthAtsBanner = ({
  clientId,
  atsType,
  closeBanner,
}: {
  clientId: string;
  atsType: ClientOnboardingAtsTypeEnum;
  closeBanner: () => void;
}): React.ReactElement => {
  const reAuth = (): void => {
    const baseUrl = window.location.origin;
    window.open(`${baseUrl}/lever?client_id=${clientId}`, "_blank", "noopener,noreferrer");
  };

  const bannerContent =
    atsType === ClientOnboardingAtsTypeEnum.Greenhouse ? (
      <BodySmall>
        In order to experience the full power of Visual Explorer,{" "}
        <ExternalLink
          href="https://help.dover.com/en/articles/6809909-adding-additional-greenhouse-permissions-for-visual-explorer"
          target="_blank"
        >
          click here
        </ExternalLink>{" "}
        for instructions on how to give Dover additional permissions to your Greenhouse.
      </BodySmall>
    ) : (
      <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
        <BodySmall>
          In order to experience the full power of Visual Explorer, please re-authenticate your Lever integration.
        </BodySmall>
        <Button variant={ButtonVariant.Secondary} onClick={reAuth}>
          Re-auth
        </Button>
      </Stack>
    );

  if (atsType !== ClientOnboardingAtsTypeEnum.Lever && atsType !== ClientOnboardingAtsTypeEnum.Greenhouse) {
    return <></>;
  }

  return (
    <Stack
      justifyContent="center"
      textAlign="center"
      sx={{ minHeight: bannerHeight, backgroundColor: colors.warning.light, position: "relative", padding: "0 30px" }}
    >
      {bannerContent}
      <CloseIcon onClick={closeBanner}>+</CloseIcon>
    </Stack>
  );
};

const MetabaseReporting = (): React.ReactElement => {
  const [greenhouseAuthBannerOpen, setGreenhouseAuthBannerOpen] = useLocalStorage<boolean>(
    GREENHOUSE_AUTH_BANNER_OPEN,
    true
  );
  const [leverReAuthBannerOpen, setLeverReAuthBannerOpen] = React.useState(false);
  const [authLoading, setAuthLoading] = React.useState(true);
  const apiApi = useOpenApiClients()?.apiApi;
  const { data: client } = useGetUsersClientQuery();
  const { data: clientOnboarding } = useGetClientOnboardingQuery();
  const [authMetabaseAPI, setAuthMetabaseAPI] = React.useState<string | null>(null);

  useEffect(() => {
    const getiFrameUrl = async (): Promise<void> => {
      setAuthLoading(true);
      const response = await apiApi?.authMetabaseUser({
        returnTo: metabaseReportingUrl,
      });
      if (response) {
        setAuthMetabaseAPI(response.metabaseUrl);
      }
      setAuthLoading(false);
    };
    try {
      getiFrameUrl();
    } catch (e) {
      console.log(e);
    }
  }, [apiApi]);

  useEffect(() => {
    // If lever is the ATS, and has not given Dover all the scopes, show the banner
    if (
      !!clientOnboarding?.atsType &&
      clientOnboarding.atsType === ClientOnboardingAtsTypeEnum.Lever &&
      !metabaseLeverScopes.every(scope => client?.atsScopes?.includes(scope))
    ) {
      setLeverReAuthBannerOpen(true);
    }
  }, [clientOnboarding, client]);

  const bannerOpen =
    client?.id &&
    clientOnboarding?.atsType &&
    (leverReAuthBannerOpen ||
      (greenhouseAuthBannerOpen && clientOnboarding?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse));

  const iframeHeight = bannerOpen ? `calc(100vh - ${bannerHeight})` : "calc(100vh)";

  if (authLoading) {
    return (
      <Centered>
        <LoadingRelative />
      </Centered>
    );
  }

  return (
    <>
      <PageHelmet title="Reporting" />
      {bannerOpen && (
        <ReAuthAtsBanner
          clientId={client?.id!}
          atsType={clientOnboarding?.atsType!}
          closeBanner={(): void => {
            if (clientOnboarding?.atsType === ClientOnboardingAtsTypeEnum.Lever) {
              setLeverReAuthBannerOpen(false);
            } else {
              setGreenhouseAuthBannerOpen(false);
            }
          }}
        />
      )}
      <iframe src={authMetabaseAPI ?? ""} style={{ width: "100%", height: iframeHeight }} frameBorder="0" />
    </>
  );
};

const CloseIcon = styled.div`
  cursor: pointer;
  transform: rotate(45deg);
  position: absolute;
  top: 12px;
  right: 24px;
  font-size: 25px;
`;

export default MetabaseReporting;
