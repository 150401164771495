import { Box, Switch } from "@mui/material";
import React, { useState } from "react";

import HelpSVG from "assets/icons/help-question.svg";
import { Tooltip } from "components/library/Tooltip";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useGetJobFunnelStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { DashboardJob, JobInterviewStageStats } from "services/openapi";
import { B2_doNotUse } from "styles/typography";
import { StageRow } from "views/Reporting/components/StageRow";
import { ReportBlock, StyledHeader, StyledTable, TableHeader } from "views/Reporting/styles";
import { StyledSVG } from "views/Reporting/styles";
import { CandidateDemographic, DateRange, SourceOptionValue } from "views/Reporting/types";

interface InterviewStatsTableProps {
  job: DashboardJob;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
  selectedDemographic: CandidateDemographic;
}

export const InterviewStatsTable: React.FC<InterviewStatsTableProps> = ({
  job,
  dateRange,
  selectedSource,
  selectedDemographic,
}) => {
  const [showPercentages, setShowPercentages] = useState<boolean>(false);

  const { data: interviewStats, isFetching } = useGetJobFunnelStatsQuery({
    id: job.id!,
    ...dateRange,
    channelName: selectedSource?.channelName,
    candidateSource: selectedSource?.candidateSource,
    demographic: selectedDemographic,
  });

  const PASS_THROUGH_RATE_TOOLTIP_TEXT = "Excludes active candidates in previous interview stages.";

  return (
    <ReportBlock>
      <Box display="flex" justifyContent="space-between">
        <StyledHeader>Interview Funnel</StyledHeader>
        <Box display="flex" alignItems="center">
          <Switch
            disabled={isFetching}
            checked={showPercentages}
            onChange={(): void => setShowPercentages(!showPercentages)}
          />
          <B2_doNotUse>Pass-through rates</B2_doNotUse>
          <Tooltip title={PASS_THROUGH_RATE_TOOLTIP_TEXT} placement="top">
            <span>
              <StyledSVG src={HelpSVG} style={{ marginLeft: "4px", marginRight: "4px" }} />
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Spacer height={20} />
      <StyledTable responsive={true} bordered>
        <thead>
          <tr>
            <TableHeader scope="col" />
            <TableHeader scope="col">Entered Stage</TableHeader>
            <TableHeader scope="col">Currently Active</TableHeader>
            <TableHeader scope="col">Lost</TableHeader>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr>
              <td colSpan={5} height="294px">
                <DoverLoadingSpinner />
              </td>
            </tr>
          ) : (
            <>
              {interviewStats?.statsByStage.map((stats: JobInterviewStageStats, idx: number) => {
                return (
                  <StageRow
                    key={idx}
                    allStats={interviewStats.statsByStage}
                    stageStats={stats}
                    job={job}
                    showPercentages={showPercentages}
                  />
                );
              })}
            </>
          )}
        </tbody>
      </StyledTable>
    </ReportBlock>
  );
};
