import { differenceInDays, parseISO, setMilliseconds, setMinutes, setSeconds, subDays } from "date-fns";
import _, { get } from "lodash";

import { CandidateSource, CandidateSourceDirectionEnum, JobInterviewStageStats } from "services/openapi";
import {
  ChannelFilterLabelEnum,
  DateOption,
  ALL_CHANNELS_OPTION,
  ALL_INBOUND_OPTION,
  ALL_OUTBOUND_OPTION,
} from "views/Reporting/constants";
import { ChannelName, DateRange, SourceOption, SourceOptionValue } from "views/Reporting/types";

export function getFilterDates(dateOption: DateOption): { start?: string; end?: string } {
  const dateRange: DateRange = {};

  // current date to the hour
  let current = new Date();
  current = setMinutes(current, 0);
  current = setSeconds(current, 0);
  current = setMilliseconds(current, 0);

  // current date as utc formatted string
  const end = current.toISOString();

  switch (dateOption) {
    case DateOption.Last7Days:
      current = subDays(current, 7);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last14Days:
      current = subDays(current, 14);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last2To4Weeks:
      dateRange.start = subDays(current, 30).toISOString();
      dateRange.end = subDays(current, 14).toISOString();
      break;
    case DateOption.Last30Days:
      current = subDays(current, 30);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last60Days:
      current = subDays(current, 60);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last90Days:
      current = subDays(current, 90);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    default:
      // DateOption.AllTime, do not set a start or end
      break;
  }

  return dateRange;
}

function removeCandidateSource(sources: CandidateSource[], internalName: string): CandidateSource | null {
  const removedSource = _.remove(sources, function(source: CandidateSource) {
    return source.internalName === internalName;
  });

  if (removedSource.length > 0) {
    return removedSource[0];
  }

  return null;
}

// given an array of candidateSources, group them for displaying in
// the channel filter dropdown as an array of SourceOptions
export function getSourceOptions(candidateSources: CandidateSource[] | undefined): SourceOption[] {
  // group sources by direction so manually created options can be inserted in order
  const sourcesByDirection = _.groupBy(candidateSources, function(b) {
    return b.direction;
  });

  // find candidateSourceIds for the Referrals SourceOption
  const candidateReferredSource = removeCandidateSource(
    sourcesByDirection[CandidateSourceDirectionEnum.Outbound],
    "CANDIDATE_REFERRED"
  );

  const referralsCandidateSourceIds: string[] = [];
  if (candidateReferredSource && candidateReferredSource.id) {
    // add spliced item to referrals SourceOption
    referralsCandidateSourceIds.push(candidateReferredSource.id);
  }

  const REFERRALS: SourceOptionValue = {
    channelName: ChannelName.REFERRALS,
    candidateSource: referralsCandidateSourceIds.length > 0 ? referralsCandidateSourceIds : undefined,
  };

  // find candidateSourceIds for the Manual SourceOption
  const manualSource = removeCandidateSource(sourcesByDirection[CandidateSourceDirectionEnum.Outbound], "MANUAL");

  const doverSourcingExtensionSource = removeCandidateSource(
    sourcesByDirection[CandidateSourceDirectionEnum.Outbound],
    "DOVER_SOURCING_EXTENSION"
  );

  const manualSourceOptionValueCandidateSourceIds: string[] = [];
  if (manualSource && manualSource.id) {
    manualSourceOptionValueCandidateSourceIds.push(manualSource.id);
  }

  if (doverSourcingExtensionSource && doverSourcingExtensionSource.id) {
    manualSourceOptionValueCandidateSourceIds.push(doverSourcingExtensionSource.id);
  }

  const MANUAL: SourceOptionValue = {
    channelName: ChannelName.OUTBOUND,
    candidateSource:
      manualSourceOptionValueCandidateSourceIds && manualSourceOptionValueCandidateSourceIds.length > 0
        ? manualSourceOptionValueCandidateSourceIds
        : undefined,
  };

  const REFERRALS_OPTION: SourceOption = {
    label: ChannelFilterLabelEnum.REFERRALS,
    value: REFERRALS,
  };

  const MANUAL_OPTION: SourceOption = {
    label: "Manual",
    value: MANUAL,
  };

  // list of all source option for rendering in dropdown selector
  const options = [ALL_CHANNELS_OPTION];

  // iterate through candidate sources by direction
  Object.values(CandidateSourceDirectionEnum).forEach(direction => {
    // add top level source option before other options
    switch (direction) {
      case CandidateSourceDirectionEnum.Inbound:
        options.push(ALL_INBOUND_OPTION);
        break;
      case CandidateSourceDirectionEnum.Outbound:
        options.push(ALL_OUTBOUND_OPTION);
        break;
      default:
        break;
    }

    // get sources for this direction
    const sources = sourcesByDirection[direction] ?? [];

    sources.forEach(source => {
      // if this source has the minimum amount of data required for displaying it on FE
      if (source.direction && source.id && source.label) {
        // create the SourceOptionValue
        const sourceOptionValue: SourceOptionValue = {
          channelName: ChannelName[source.direction],
          candidateSource: [source.id],
        };

        // create SourceOption
        const sourceOption: SourceOption = {
          label: source.label,
          value: sourceOptionValue,
        };

        // add the source option to list
        options.push(sourceOption);
      }
    });

    // Add manual option and referrals at the end of the outbound list
    if (direction === CandidateSourceDirectionEnum.Outbound) {
      // add manual option only if there were sources as part of this option
      if (manualSourceOptionValueCandidateSourceIds.length > 0) {
        options.push(MANUAL_OPTION);
      }

      if (referralsCandidateSourceIds.length > 0) {
        options.push(REFERRALS_OPTION);
      }
    }
  });

  return options;
}

export function isDateFilterRangeLessThanTwoWeeks(dateRange: DateRange): boolean {
  if (isDateFilterApplied(dateRange)) {
    if (differenceInDays(parseISO(dateRange.end!), parseISO(dateRange.start!)) <= 14) {
      return true;
    }

    return false;
  }

  return false;
}

function isDateFilterApplied(dateRange: DateRange): boolean {
  return !!(Object.keys(dateRange).length > 0);
}

function getPassThroughRate(currentStat: number, prevStat: number): string {
  const passThroughRate: number = Math.round((currentStat / prevStat) * 100);
  return `(${passThroughRate}% from previous stage)`;
}

// * Given all interview Stats, and the current stage's stats, find the pass through rate for the given stage
export function renderPassThroughRate(
  interviewStats: JobInterviewStageStats[],
  currentStageStats: JobInterviewStageStats
): string {
  // keys to look for in interviewStats
  const statTotal = "total";
  const statActive = "active";

  const currentStageIndex = currentStageStats ? interviewStats.indexOf(currentStageStats) : undefined;
  let prevStageStats: JobInterviewStageStats | undefined = undefined;
  if (currentStageIndex) {
    // we don't accept 0 either cuz no previous stage before 0'th element
    prevStageStats = interviewStats[currentStageIndex - 1];
  }

  if (currentStageStats && prevStageStats) {
    // get the total number of candidates for the current and previous stage
    const statValue = get(currentStageStats, statTotal);
    const totalPrevStatValue = get(prevStageStats, statTotal);

    if (statValue && totalPrevStatValue) {
      // get the number of active candidates for the previous stage
      const activePrevStatValue = get(prevStageStats, statActive, 0) || 0;
      // get the number of candidates that have already gone through the prev stage
      const prevStatValue = totalPrevStatValue - activePrevStatValue;

      return getPassThroughRate(statValue, prevStatValue);
    }
  }

  return "";
}

export function isChannelRelevant(selectedSource: SourceOptionValue, channelName: string): boolean {
  // no filter: show all channels
  if (!selectedSource.channelName) {
    return true;
  }

  // channel specific filter
  if (selectedSource.channelName && selectedSource.channelName === channelName) {
    return true;
  }

  // hide all other channels
  return false;
}

export function isQueriedSourceOptionValid(
  queriedSourceOption: SourceOption | null | undefined,
  validSourceOptions: SourceOption[]
): boolean {
  if (!queriedSourceOption) {
    return false;
  }

  return (
    validSourceOptions.findIndex(validSourceOption => {
      const labelMatches = validSourceOption.label === queriedSourceOption.label;
      const channelMatches = validSourceOption.value.channelName === queriedSourceOption.value.channelName;

      if (!labelMatches || !channelMatches) {
        return false;
      }

      const validCandidateIds = validSourceOption.value.candidateSource;
      const queriedIds = queriedSourceOption.value.candidateSource;

      if (validCandidateIds === undefined || queriedIds === undefined) {
        return validCandidateIds === queriedIds;
      }

      // Both sets of candidates sources need to be undefined, or defined and the same length
      if (validCandidateIds.length !== queriedIds.length) {
        return false;
      }

      return validCandidateIds.every((candidateId, i) => {
        return candidateId === queriedIds[i];
      });
    }) !== -1
  );
}

export function isSourceOption(obj: any): obj is SourceOption {
  return (
    (obj.value.channelName === undefined || Object.values(ChannelName).includes(obj.value.channelName)) &&
    obj.label && // Don't only check for undefined, but also empty string (which is falsy)
    (obj.value.candidateSource === undefined || Array.isArray(obj.value.candidateSource))
  );
}
