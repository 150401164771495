import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import HelpSVG from "assets/icons/help-question.svg";
import { Tooltip } from "components/library/Tooltip";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useGetJobOutreachBenchmarksQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { B3_doNotUse } from "styles/typography";
import { renderOutboundBenchmark } from "views/Reporting/components/ChannelTiles/utils";
import { StyledSVG } from "views/Reporting/styles";
import { ChannelName, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

const StyledB3 = styled(B3_doNotUse)`
  color: ${colors.grayscale.gray600};
`;

interface OutreachBenchmarkProps {
  jobId: string;
  outreachBenchmarkKey: "responseRate" | "interestedRate";
  selectedSource: SourceOptionValue;
}

export const OutreachBenchmark = ({
  jobId,
  outreachBenchmarkKey,
  selectedSource,
}: OutreachBenchmarkProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.OUTBOUND);

  const { data: outreachBenchmarks, isFetching } = useGetJobOutreachBenchmarksQuery(jobId);

  const toolTipText = "Rate is calculated using an average across similar jobs in Dover.";

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <div style={{ display: "flex" }}>
        <StyledB3>Benchmark</StyledB3>
        <Spacer width={3} />
        <Tooltip title={toolTipText}>
          <span>
            <StyledSVG src={HelpSVG} />
          </span>
        </Tooltip>
      </div>
      {isFetching ? (
        <DoverLoadingSpinner spinnerSize="12px" minHeight="12px" height="12px" width="12px" />
      ) : (
        <StyledB3>{showStats ? renderOutboundBenchmark(outreachBenchmarks, outreachBenchmarkKey) : <>--</>}</StyledB3>
      )}
    </Box>
  );
};
