/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseJobSetupProUser,
    BaseJobSetupProUserFromJSON,
    BaseJobSetupProUserFromJSONTyped,
    BaseJobSetupProUserToJSON,
    JobCompensation,
    JobCompensationFromJSON,
    JobCompensationFromJSONTyped,
    JobCompensationToJSON,
    JobFAQ,
    JobFAQFromJSON,
    JobFAQFromJSONTyped,
    JobFAQToJSON,
    JobLocation,
    JobLocationFromJSON,
    JobLocationFromJSONTyped,
    JobLocationToJSON,
    JobSetupSearch,
    JobSetupSearchFromJSON,
    JobSetupSearchFromJSONTyped,
    JobSetupSearchToJSON,
    PlatformSchedulingEmailAlias,
    PlatformSchedulingEmailAliasFromJSON,
    PlatformSchedulingEmailAliasFromJSONTyped,
    PlatformSchedulingEmailAliasToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobSetup
 */
export interface JobSetup {
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly client?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly clientName?: string;
    /**
     * 
     * @type {BaseJobSetupProUser}
     * @memberof JobSetup
     */
    hiringManager?: BaseJobSetupProUser;
    /**
     * 
     * @type {BaseJobSetupProUser}
     * @memberof JobSetup
     */
    recruiter?: BaseJobSetupProUser;
    /**
     * 
     * @type {Array<BaseJobSetupProUser>}
     * @memberof JobSetup
     */
    readonly hiringTeam?: Array<BaseJobSetupProUser>;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    decidedToSkipOnboardingCall?: boolean | null;
    /**
     * Deprecated - Notes that are customer facing. Often used to keep track of a customer's calibration feedback.
     * @type {string}
     * @memberof JobSetup
     */
    externalNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly relevantJobSetupSteps?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    jobCreationContext?: JobSetupJobCreationContextEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    readonly gatedFromApp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    readonly isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    readonly isSample?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    emailSender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupEmailState?: JobSetupSetupEmailStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    shouldSendInboundRejectionEmails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    shouldSendApplicationConfirmationEmails?: boolean;
    /**
     * 
     * @type {Array<JobFAQ>}
     * @memberof JobSetup
     */
    faqs?: Array<JobFAQ>;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    shouldEngagePassive?: JobSetupShouldEngagePassiveEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    workEmailOk?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupJobRequirementsState?: JobSetupSetupJobRequirementsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupJobPitchState?: JobSetupSetupJobPitchStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupJdState?: JobSetupSetupJdStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    readonly jpqExists?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupOutreachState?: JobSetupSetupOutreachStateEnum;
    /**
     * 
     * @type {Array<JobLocation>}
     * @memberof JobSetup
     */
    locations?: Array<JobLocation>;
    /**
     * 
     * @type {JobCompensation}
     * @memberof JobSetup
     */
    compensation?: JobCompensation;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    visaSupport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    interviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    scheduler?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    schedulingCcEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    schedulingBody?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    schedulingCalendarGcalId?: string | null;
    /**
     * 
     * @type {PlatformSchedulingEmailAlias}
     * @memberof JobSetup
     */
    workSchedulingEmailAlias: PlatformSchedulingEmailAlias;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    doverInterviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    secondRoundScheduler?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    secondRoundInterviewer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    setupInterviewerEmailState?: JobSetupSetupInterviewerEmailStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    setupSecondRoundSchedulingState?: JobSetupSetupSecondRoundSchedulingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly initialCallJirtId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupInterviewRubricState?: JobSetupSetupInterviewRubricStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly clientDefaultFeedbackTemplate?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    atsBehalfId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    atsSyncSetting?: JobSetupAtsSyncSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    atsJobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    atsJobId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupAtsState?: JobSetupSetupAtsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    readonly atsCredentialsProvided?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly slackChannelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly slackAuthUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    readonly setupSlackState?: JobSetupSetupSlackStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    clientSchedulingEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    setupCalibrationState?: JobSetupSetupCalibrationStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    active?: boolean;
    /**
     * Reason that should be filled out by EM
     * @type {string}
     * @memberof JobSetup
     */
    deactivationReason?: JobSetupDeactivationReasonEnum;
    /**
     * 
     * @type {Date}
     * @memberof JobSetup
     */
    deactivatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    snoozedReason?: JobSetupSnoozedReasonEnum;
    /**
     * 
     * @type {Date}
     * @memberof JobSetup
     */
    lastSnoozedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof JobSetup
     */
    snoozedUntil?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    shouldHideCompensationInternally?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    autoSearchAdjustmentsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetup
     */
    pipelineReviewCallsEnabled?: boolean;
    /**
     * 
     * @type {JobSetupSearch}
     * @memberof JobSetup
     */
    primarySearch?: JobSetupSearch | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetup
     */
    customerFacingNotes?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof JobSetup
     */
    goalHireDate?: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum JobSetupJobCreationContextEnum {
    Default = 'DEFAULT',
    Free = 'FREE',
    FreeAts = 'FREE_ATS',
    LimitedOutbound = 'LIMITED_OUTBOUND',
    FreeSourcingExtension = 'FREE_SOURCING_EXTENSION',
    LimitedOutboundJd = 'LIMITED_OUTBOUND_JD'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupEmailStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupShouldEngagePassiveEnum {
    All = 'All',
    InterestedInRole = 'Interested In Role',
    ActiveOnly = 'Active Only'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupJobRequirementsStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupJobPitchStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupJdStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupOutreachStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupInterviewerEmailStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupSecondRoundSchedulingStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupInterviewRubricStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupAtsSyncSettingEnum {
    Sourced = 'SOURCED',
    FirstContacted = 'FIRST_CONTACTED',
    Responded = 'RESPONDED',
    ReplyTypeSet = 'REPLY_TYPE_SET',
    CallCompleted = 'CALL_COMPLETED',
    CallScheduling = 'CALL_SCHEDULING',
    AddManually = 'ADD_MANUALLY'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupAtsStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupSlackStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSetupCalibrationStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupDeactivationReasonEnum {
    Hired = 'Hired',
    HiredThroughDover = 'Hired Through Dover',
    HiredOutsideDover = 'Hired Outside Dover',
    ContractEnded = 'Contract Ended',
    ContractEndedAndStillHiring = 'Contract Ended and still hiring',
    ContractEndedAndNoLongerHiring = 'Contract Ended and no longer hiring',
    DePrioritizedJob = 'De-prioritized Job',
    DePrioritizedJobNoLongerHiring = 'De-prioritized Job - No Longer Hiring',
    DePrioritizedJobStillHiring = 'De-prioritized Job - Still Hiring',
    DuplicateJob = 'Duplicate Job',
    Other = 'Other'
}/**
* @export
* @enum {string}
*/
export enum JobSetupSnoozedReasonEnum {
    OutOfOffice = 'Out of Office',
    HasCandidatesInProcess = 'Has Candidates in Process',
    DontNeedToHireForThisJob = 'Dont Need to Hire for this Job',
    NeedToRecalibrateSearch = 'Need to recalibrate search',
    Other = 'Other'
}

export function JobSetupFromJSON(json: any): JobSetup {
    return JobSetupFromJSONTyped(json, false);
}

export function JobSetupFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSetup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'clientName': !exists(json, 'client_name') ? undefined : json['client_name'],
        'hiringManager': !exists(json, 'hiring_manager') ? undefined : BaseJobSetupProUserFromJSON(json['hiring_manager']),
        'recruiter': !exists(json, 'recruiter') ? undefined : BaseJobSetupProUserFromJSON(json['recruiter']),
        'hiringTeam': !exists(json, 'hiring_team') ? undefined : ((json['hiring_team'] as Array<any>).map(BaseJobSetupProUserFromJSON)),
        'decidedToSkipOnboardingCall': !exists(json, 'decided_to_skip_onboarding_call') ? undefined : json['decided_to_skip_onboarding_call'],
        'externalNotes': !exists(json, 'external_notes') ? undefined : json['external_notes'],
        'relevantJobSetupSteps': !exists(json, 'relevant_job_setup_steps') ? undefined : json['relevant_job_setup_steps'],
        'jobCreationContext': !exists(json, 'job_creation_context') ? undefined : json['job_creation_context'],
        'gatedFromApp': !exists(json, 'gated_from_app') ? undefined : json['gated_from_app'],
        'isPrivate': !exists(json, 'is_private') ? undefined : json['is_private'],
        'isSample': !exists(json, 'is_sample') ? undefined : json['is_sample'],
        'emailSender': !exists(json, 'email_sender') ? undefined : json['email_sender'],
        'setupEmailState': !exists(json, 'setup_email_state') ? undefined : json['setup_email_state'],
        'shouldSendInboundRejectionEmails': !exists(json, 'should_send_inbound_rejection_emails') ? undefined : json['should_send_inbound_rejection_emails'],
        'shouldSendApplicationConfirmationEmails': !exists(json, 'should_send_application_confirmation_emails') ? undefined : json['should_send_application_confirmation_emails'],
        'faqs': !exists(json, 'faqs') ? undefined : ((json['faqs'] as Array<any>).map(JobFAQFromJSON)),
        'shouldEngagePassive': !exists(json, 'should_engage_passive') ? undefined : json['should_engage_passive'],
        'workEmailOk': !exists(json, 'work_email_ok') ? undefined : json['work_email_ok'],
        'setupJobRequirementsState': !exists(json, 'setup_job_requirements_state') ? undefined : json['setup_job_requirements_state'],
        'setupJobPitchState': !exists(json, 'setup_job_pitch_state') ? undefined : json['setup_job_pitch_state'],
        'setupJdState': !exists(json, 'setup_jd_state') ? undefined : json['setup_jd_state'],
        'jpqExists': !exists(json, 'jpq_exists') ? undefined : json['jpq_exists'],
        'campaignId': !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        'setupOutreachState': !exists(json, 'setup_outreach_state') ? undefined : json['setup_outreach_state'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(JobLocationFromJSON)),
        'compensation': !exists(json, 'compensation') ? undefined : JobCompensationFromJSON(json['compensation']),
        'visaSupport': !exists(json, 'visa_support') ? undefined : json['visa_support'],
        'interviewer': !exists(json, 'interviewer') ? undefined : json['interviewer'],
        'scheduler': !exists(json, 'scheduler') ? undefined : json['scheduler'],
        'schedulingCcEmail': !exists(json, 'scheduling_cc_email') ? undefined : json['scheduling_cc_email'],
        'schedulingBody': !exists(json, 'scheduling_body') ? undefined : json['scheduling_body'],
        'schedulingCalendarGcalId': !exists(json, 'scheduling_calendar_gcal_id') ? undefined : json['scheduling_calendar_gcal_id'],
        'workSchedulingEmailAlias': PlatformSchedulingEmailAliasFromJSON(json['work_scheduling_email_alias']),
        'doverInterviewer': !exists(json, 'dover_interviewer') ? undefined : json['dover_interviewer'],
        'secondRoundScheduler': !exists(json, 'second_round_scheduler') ? undefined : json['second_round_scheduler'],
        'secondRoundInterviewer': !exists(json, 'second_round_interviewer') ? undefined : json['second_round_interviewer'],
        'setupInterviewerEmailState': !exists(json, 'setup_interviewer_email_state') ? undefined : json['setup_interviewer_email_state'],
        'setupSecondRoundSchedulingState': !exists(json, 'setup_second_round_scheduling_state') ? undefined : json['setup_second_round_scheduling_state'],
        'initialCallJirtId': !exists(json, 'initial_call_jirt_id') ? undefined : json['initial_call_jirt_id'],
        'setupInterviewRubricState': !exists(json, 'setup_interview_rubric_state') ? undefined : json['setup_interview_rubric_state'],
        'clientDefaultFeedbackTemplate': !exists(json, 'client_default_feedback_template') ? undefined : json['client_default_feedback_template'],
        'atsBehalfId': !exists(json, 'ats_behalf_id') ? undefined : json['ats_behalf_id'],
        'atsSyncSetting': !exists(json, 'ats_sync_setting') ? undefined : json['ats_sync_setting'],
        'atsJobTitle': !exists(json, 'ats_job_title') ? undefined : json['ats_job_title'],
        'atsJobId': !exists(json, 'ats_job_id') ? undefined : json['ats_job_id'],
        'setupAtsState': !exists(json, 'setup_ats_state') ? undefined : json['setup_ats_state'],
        'atsCredentialsProvided': !exists(json, 'ats_credentials_provided') ? undefined : json['ats_credentials_provided'],
        'slackChannelId': !exists(json, 'slack_channel_id') ? undefined : json['slack_channel_id'],
        'slackAuthUrl': !exists(json, 'slack_auth_url') ? undefined : json['slack_auth_url'],
        'setupSlackState': !exists(json, 'setup_slack_state') ? undefined : json['setup_slack_state'],
        'clientSchedulingEmail': !exists(json, 'client_scheduling_email') ? undefined : json['client_scheduling_email'],
        'setupCalibrationState': !exists(json, 'setup_calibration_state') ? undefined : json['setup_calibration_state'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'deactivationReason': !exists(json, 'deactivation_reason') ? undefined : json['deactivation_reason'],
        'deactivatedAt': !exists(json, 'deactivated_at') ? undefined : (json['deactivated_at'] === null ? null : new Date(json['deactivated_at'])),
        'snoozedReason': !exists(json, 'snoozed_reason') ? undefined : json['snoozed_reason'],
        'lastSnoozedAt': !exists(json, 'last_snoozed_at') ? undefined : (json['last_snoozed_at'] === null ? null : new Date(json['last_snoozed_at'])),
        'snoozedUntil': !exists(json, 'snoozed_until') ? undefined : (json['snoozed_until'] === null ? null : new Date(json['snoozed_until'])),
        'shouldHideCompensationInternally': !exists(json, 'should_hide_compensation_internally') ? undefined : json['should_hide_compensation_internally'],
        'autoSearchAdjustmentsEnabled': !exists(json, 'auto_search_adjustments_enabled') ? undefined : json['auto_search_adjustments_enabled'],
        'pipelineReviewCallsEnabled': !exists(json, 'pipeline_review_calls_enabled') ? undefined : json['pipeline_review_calls_enabled'],
        'primarySearch': !exists(json, 'primary_search') ? undefined : JobSetupSearchFromJSON(json['primary_search']),
        'customerFacingNotes': !exists(json, 'customer_facing_notes') ? undefined : json['customer_facing_notes'],
        'goalHireDate': !exists(json, 'goal_hire_date') ? undefined : (json['goal_hire_date'] === null ? null : new Date(json['goal_hire_date'])),
    };
}

export function JobSetupToJSON(value?: JobSetup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'hiring_manager': BaseJobSetupProUserToJSON(value.hiringManager),
        'recruiter': BaseJobSetupProUserToJSON(value.recruiter),
        'decided_to_skip_onboarding_call': value.decidedToSkipOnboardingCall,
        'external_notes': value.externalNotes,
        'job_creation_context': value.jobCreationContext,
        'email_sender': value.emailSender,
        'should_send_inbound_rejection_emails': value.shouldSendInboundRejectionEmails,
        'should_send_application_confirmation_emails': value.shouldSendApplicationConfirmationEmails,
        'faqs': value.faqs === undefined ? undefined : ((value.faqs as Array<any>).map(JobFAQToJSON)),
        'should_engage_passive': value.shouldEngagePassive,
        'work_email_ok': value.workEmailOk,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(JobLocationToJSON)),
        'compensation': JobCompensationToJSON(value.compensation),
        'visa_support': value.visaSupport,
        'interviewer': value.interviewer,
        'scheduler': value.scheduler,
        'scheduling_cc_email': value.schedulingCcEmail,
        'scheduling_body': value.schedulingBody,
        'scheduling_calendar_gcal_id': value.schedulingCalendarGcalId,
        'work_scheduling_email_alias': PlatformSchedulingEmailAliasToJSON(value.workSchedulingEmailAlias),
        'dover_interviewer': value.doverInterviewer,
        'second_round_scheduler': value.secondRoundScheduler,
        'second_round_interviewer': value.secondRoundInterviewer,
        'setup_interviewer_email_state': value.setupInterviewerEmailState,
        'setup_second_round_scheduling_state': value.setupSecondRoundSchedulingState,
        'ats_behalf_id': value.atsBehalfId,
        'ats_sync_setting': value.atsSyncSetting,
        'ats_job_title': value.atsJobTitle,
        'ats_job_id': value.atsJobId,
        'client_scheduling_email': value.clientSchedulingEmail,
        'setup_calibration_state': value.setupCalibrationState,
        'active': value.active,
        'deactivation_reason': value.deactivationReason,
        'deactivated_at': value.deactivatedAt === undefined ? undefined : (value.deactivatedAt === null ? null : value.deactivatedAt.toISOString()),
        'snoozed_reason': value.snoozedReason,
        'last_snoozed_at': value.lastSnoozedAt === undefined ? undefined : (value.lastSnoozedAt === null ? null : value.lastSnoozedAt.toISOString()),
        'snoozed_until': value.snoozedUntil === undefined ? undefined : (value.snoozedUntil === null ? null : value.snoozedUntil.toISOString()),
        'should_hide_compensation_internally': value.shouldHideCompensationInternally,
        'auto_search_adjustments_enabled': value.autoSearchAdjustmentsEnabled,
        'pipeline_review_calls_enabled': value.pipelineReviewCallsEnabled,
        'primary_search': JobSetupSearchToJSON(value.primarySearch),
        'customer_facing_notes': value.customerFacingNotes,
        'goal_hire_date': value.goalHireDate === undefined ? undefined : (value.goalHireDate === null ? null : value.goalHireDate.toISOString()),
    };
}


