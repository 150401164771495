import { createEnumParam, decodeJson, encodeJson, QueryParamConfig } from "use-query-params";

import { CandidateDemographic, ChannelName, SourceOption, SourceOptionValue } from "views/Reporting/types";
import { isSourceOption } from "views/Reporting/utils";

export const INSUFFICIENT_DATA_ALERT_MESSAGE: string =
  "Insufficient data in this timeframe. Please select a longer timeframe to view this data.";

export enum DateOption {
  AllTime = "ALL_TIME",
  Last7Days = "LAST_7_DAYS",
  Last14Days = "LAST_14_DAYS",
  Last2To4Weeks = "LAST_2_TO_4_WEEKS",
  Last30Days = "LAST_30_DAYS",
  Last60Days = "LAST_60_DAYS",
  Last90Days = "LAST_90_DAYS",
}

interface DateFilterOption {
  key: DateOption;
  label: string;
}

export const JOB_REPORT_DATE_FILTER_OPTIONS: Array<DateFilterOption> = [
  { key: DateOption.AllTime, label: "All time" },
  { key: DateOption.Last7Days, label: "Last 7 days" },
  { key: DateOption.Last14Days, label: "Last 14 days" },
  { key: DateOption.Last30Days, label: "Last 30 days" },
  { key: DateOption.Last60Days, label: "Last 60 days" },
  { key: DateOption.Last90Days, label: "Last 90 days" },
];

export enum ChannelFilterLabelEnum {
  ALL = "All channels",
  ALL_INBOUND = "All inbound",
  ALL_OUTBOUND = "All outbound",
  REFERRALS = "Referrals",
}

// values for filtering by channel for reporting endpoints
const ALL_CHANNELS: Readonly<SourceOptionValue> = {
  channelName: undefined,
  candidateSource: undefined,
};

const ALL_INBOUND: Readonly<SourceOptionValue> = {
  channelName: ChannelName.INBOUND,
  candidateSource: undefined,
};

const ALL_OUTBOUND: Readonly<SourceOptionValue> = {
  channelName: ChannelName.OUTBOUND,
  candidateSource: undefined,
};

// SourceOptions for channels dropdown
export const ALL_CHANNELS_OPTION: Readonly<SourceOption> = {
  label: ChannelFilterLabelEnum.ALL,
  value: ALL_CHANNELS,
};

export const ALL_INBOUND_OPTION: Readonly<SourceOption> = {
  label: ChannelFilterLabelEnum.ALL_INBOUND,
  value: ALL_INBOUND,
};

export const ALL_OUTBOUND_OPTION: Readonly<SourceOption> = {
  label: ChannelFilterLabelEnum.ALL_OUTBOUND,
  value: ALL_OUTBOUND,
};

export const SourceOptionParam: QueryParamConfig<SourceOption | null | undefined> = {
  encode: (value: SourceOption | null | undefined): string | null | undefined => encodeJson(value),
  decode: (jsonStr: string | (string | null)[] | null | undefined): SourceOption | undefined => {
    if (!jsonStr) {
      return undefined;
    }

    const potentialSourceOption = decodeJson(jsonStr);
    return isSourceOption(potentialSourceOption) ? potentialSourceOption : undefined;
  },
};

export const dateRangeOptionQueryParamName = "dateOption";
export const DateRangeOptionQueryParamType = createEnumParam(Object.values(DateOption));

export const demographicQueryParamName = "demographic";
export const DemographicQueryParamType = createEnumParam(Object.values(CandidateDemographic));

export const metabaseReportingUrl =
  "https://dover.metabaseapp.com/collection/39-my-reporting?new_button=false&action_buttons=fale&top_nav=false&side_nav=true&header=false&additional_info=false";
