import { z } from "zod";

import { KNOWN_PERSONAL_DOMAINS } from "components/dover/CompanySetupBasicInfo/constants";
import { DOVER_LINKEDIN_COMPANY_ID, LINKEDIN_COMPANY_URL_PREFIX } from "components/dover/constants";

export const formSchema = z.object({
  companyName: z
    .string()
    .min(1, { message: "Required" })
    .refine(val => /^[\x20-\x7E]+$/.test(val), "Company name can only contain ASCII characters"),
  linkedInUrl: z
    .string()
    .min(1, { message: "Required" })
    .url()
    .startsWith(LINKEDIN_COMPANY_URL_PREFIX, {
      message: `Please add a LinkedIn page in the following format: ${LINKEDIN_COMPANY_URL_PREFIX}your-company-name`,
    })
    .refine(url => !url.endsWith(DOVER_LINKEDIN_COMPANY_ID) && !url.slice(0, -1).endsWith(DOVER_LINKEDIN_COMPANY_ID), {
      message: "Please use your company's LinkedIn page, not Dover's",
    }),
  newCompanyDomains: z
    .record(
      z
        .string()
        .min(5, { message: "Required" })
        .regex(/([0-9a-z]{2,})\.([0-9a-z]{2,})/i, {
          message: "Please add a domain in the following format: dover.com",
        })
        .refine(domain => !KNOWN_PERSONAL_DOMAINS.includes(domain), {
          message: "Personal email domains cannot be added as company domains.",
        })
    )
    .default({}),
});

export type FormSchema = z.infer<typeof formSchema>;

export interface KeyObject {
  key: string;
}
