import React from "react";
import { ReactSVG } from "react-svg";

import CalendarSVG from "assets/icons/calendar-outlined-grey.svg";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useGetJobReportMetaQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";
import { getLocalDateString } from "utils/dates";
import { StyledBox } from "views/Reporting/styles";

interface KickoffDateProps {
  jobId: string;
}

export const KickoffDate = ({ jobId }: KickoffDateProps): React.ReactElement => {
  const { data: jobReportMetaData, isFetching: isFetchingJobReportMetaData } = useGetJobReportMetaQuery(jobId);

  return (
    <StyledBox>
      {CalendarSVG && <ReactSVG src={CalendarSVG} />}
      <Spacer width={8} />
      <B2_doNotUse $color={colors.grayscale.gray600}>Kickoff date:</B2_doNotUse>
      <Spacer width={4} />
      {isFetchingJobReportMetaData ? (
        <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
      ) : (
        <>
          {jobReportMetaData && jobReportMetaData.kickoffTimestamp ? (
            <B2_doNotUse $color={colors.grayscale.gray600}>
              {getLocalDateString(jobReportMetaData.kickoffTimestamp)}
            </B2_doNotUse>
          ) : (
            <B2_doNotUse $color={colors.grayscale.gray600}>N/A</B2_doNotUse>
          )}
        </>
      )}
    </StyledBox>
  );
};
