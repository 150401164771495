import { Autocomplete } from "@mui/material";
import React from "react";

import { StyledTextField } from "components/dover/inputs/pro-users/styles";
import { DateOption, JOB_REPORT_DATE_FILTER_OPTIONS } from "views/Reporting/constants";

interface DateDropdownSelectorProps {
  value: DateOption;
  onChange: (option: DateOption) => void;
}

export const DateDropdownSelector = ({ value, onChange }: DateDropdownSelectorProps): React.ReactElement => {
  return (
    <Autocomplete
      sx={{
        legend: { display: "none" },
        ".MuiInputBase-root": {
          border: "none",
        },
      }}
      disableClearable
      value={JOB_REPORT_DATE_FILTER_OPTIONS.find(option => option.key === value)}
      onChange={(_event, newValue): void => {
        if (newValue) {
          onChange(newValue.key);
        }
      }}
      options={JOB_REPORT_DATE_FILTER_OPTIONS}
      renderInput={(params): React.ReactElement => {
        return <StyledTextField {...params} size="small" />;
      }}
    />
  );
};
