import { Box } from "@mui/material";
import React from "react";

import DoverOutboundModal from "components/dover/dover-outbound-modal/DoverOutboundModal";
import { Spacer } from "components/Spacer";
import { useGetDoverOutboundCandidateSource } from "services/doverapi/cross-endpoint-hooks/useGetDoverOutboundCandidateSource";
import { useListJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/jobCandidateSourceSetting";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { JobCandidateSourceSettingDesiredStateEnum } from "services/openapi";
import { OutreachBenchmark } from "views/Reporting/components/ChannelTiles/Outbound/OutreachBenchmark";
import { OutreachStat } from "views/Reporting/components/ChannelTiles/Outbound/OutreachStat";
import { CandidateDemographic, DateRange, SourceOptionValue } from "views/Reporting/types";

interface OutboundChannelDetailProps {
  jobId: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
  selectedDemographic: CandidateDemographic;
}
export const OutboundChannelDetail = ({
  jobId,
  dateRange,
  selectedSource,
  selectedDemographic,
}: OutboundChannelDetailProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const { data: jobCandidateSourceSettings } = useListJobCandidateSourceSettingsQuery({
    job: jobId,
    direction: "OUTBOUND",
  });

  const doverOutboundCandidateSource = useGetDoverOutboundCandidateSource(jobId);

  const doverOutboundJobCandidateSourceSettings = React.useMemo(() => {
    const allJobCandidateSourceSettings = listAllEntities(jobCandidateSourceSettings) ?? [];
    if (allJobCandidateSourceSettings.length === 0 || doverOutboundCandidateSource === undefined) {
      return undefined;
    }

    return allJobCandidateSourceSettings.find(
      sourceSetting => sourceSetting.candidateSource!.id === doverOutboundCandidateSource.id
    );
  }, [doverOutboundCandidateSource, jobCandidateSourceSettings]);

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch">
      <DoverOutboundModal
        isModalOpen={isModalOpen}
        candidateSource={doverOutboundCandidateSource}
        isDoverOutboundEnabled={
          doverOutboundJobCandidateSourceSettings?.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active
        }
        toggleModalOff={(): void => {
          setIsModalOpen(false);
        }}
      />
      <div>
        <OutreachStat
          jobId={jobId}
          outreachStatsKey="responseRate"
          label="Reply Rate"
          dateRange={dateRange}
          selectedSource={selectedSource}
          selectedDemographic={selectedDemographic}
        />
        <OutreachBenchmark jobId={jobId} outreachBenchmarkKey="responseRate" selectedSource={selectedSource} />
      </div>
      <Spacer height={24} />
      <div>
        <OutreachStat
          jobId={jobId}
          outreachStatsKey="interestedRate"
          label="Interest Rate"
          dateRange={dateRange}
          selectedSource={selectedSource}
          selectedDemographic={selectedDemographic}
        />
        <OutreachBenchmark jobId={jobId} outreachBenchmarkKey="interestedRate" selectedSource={selectedSource} />
      </div>
      <Spacer height={24} />
    </Box>
  );
};
