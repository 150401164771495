import React from "react";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { SectionHeader } from "styles/typography";

interface CandidatesReviewedStatProps {
  numCandidatesReviewed: number | undefined;
  isLoading?: boolean;
  showStats?: boolean;
}

export const CandidatesReviewedStat = ({
  numCandidatesReviewed,
  isLoading = false,
  showStats = true,
}: CandidatesReviewedStatProps): React.ReactElement => {
  return (
    <SectionHeader>
      {isLoading ? (
        <DoverLoadingSpinner spinnerSize="26px" minHeight="26px" height="26px" width="26px" />
      ) : (
        <>{numCandidatesReviewed === undefined || !showStats ? <>--</> : <div>{numCandidatesReviewed}</div>}</>
      )}
    </SectionHeader>
  );
};
