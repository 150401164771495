import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { ReactSVG } from "react-svg";

import PeopleSVG from "assets/icons/people-outlined-grey.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import useUsersWhoUploadedConnections from "services/doverapi/cross-endpoint-hooks/useUsersWhoUploadedConnections";
import { useGetJobReferralsStatsQuery, useGetJobReportMetaQuery } from "services/doverapi/endpoints/job/endpoints";
import { UserUploadedFileUploadedContextEnum } from "services/openapi";
import { PotentialReferralsCandidates } from "views/Reporting/components/ChannelTiles/Referrals/PotentialReferralsCandidates";
import { ReferralsContacted } from "views/Reporting/components/ChannelTiles/Referrals/ReferralsContacted";
import { ReferralsUploadedConnections } from "views/Reporting/components/ChannelTiles/Referrals/ReferralsUploadedConnections";
import { ChannelName, DateRange, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface ReferralsChannelDetailProps {
  jobId: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
}

export const ReferralsChannelDetail = ({
  jobId,
  dateRange,
  selectedSource,
}: ReferralsChannelDetailProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.REFERRALS);

  const usersWhoUploadedConnections = useUsersWhoUploadedConnections(UserUploadedFileUploadedContextEnum.Referrals);
  const numUsersUploadedConnections = usersWhoUploadedConnections?.length;

  const { data: referralsStats, isFetching: isFetchingReferralsStats } = useGetJobReferralsStatsQuery(
    showStats
      ? {
          id: jobId,
          ...dateRange,
        }
      : skipToken
  );

  const { data: jobReportMetaData } = useGetJobReportMetaQuery(jobId);

  if (isFetchingReferralsStats || numUsersUploadedConnections === undefined || !jobReportMetaData) {
    return <DoverLoadingSpinner />;
  }

  const inviteTeamUrl = "https://help.dover.com/en/articles/6229699-inviting-your-team-to-upload-their-connections";

  if (numUsersUploadedConnections === 0) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="flex-end">
          {PeopleSVG && <ReactSVG src={PeopleSVG} />}
          <Spacer width={8} />
          <BodySmall weight="600">No connections uploaded</BodySmall>
        </Box>
        <Spacer height={24} />
        <BodySmall centered>
          Dover referrals helps you identify great candidates within your team&apos;s network.
        </BodySmall>
        <Spacer height={24} />
        <Button
          variant={ButtonVariant.Secondary}
          onClick={(): void => {
            window.open(inviteTeamUrl, "_blank", "noopener noreferrer");
          }}
        >
          <BodySmall>Invite Team</BodySmall>
        </Button>
      </Box>
    );
  }

  if (jobReportMetaData?.numPotentialReferralCandidates === 0 && referralsStats?.numContactedReferrals === 0) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <BodySmall weight="600">No matching referrals</BodySmall>
        <Spacer height={24} />
        <BodySmall centered>
          Reach out to your Dover success team or invite more of your team to upload their connections.
        </BodySmall>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="stretch">
      <PotentialReferralsCandidates jobId={jobId} showStats={showStats} />
      <Spacer height={24} />
      <ReferralsContacted jobId={jobId} dateRange={dateRange} showStats={showStats} />
      <Spacer height={24} />
      <ReferralsUploadedConnections numUsersUploadedConnections={numUsersUploadedConnections} showStats={showStats} />
    </Box>
  );
};
