import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetJobInboundStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { B2_doNotUse } from "styles/typography";
import { ChannelName, DateRange, SourceOptionValue } from "views/Reporting/types";
import { isChannelRelevant } from "views/Reporting/utils";

interface PotentialInboundCandidatesProps {
  jobId: string;
  dateRange: DateRange;
  selectedSource: SourceOptionValue;
}

export const PotentialInboundCandidates = ({
  jobId,
  dateRange,
  selectedSource,
}: PotentialInboundCandidatesProps): React.ReactElement => {
  const showStats = isChannelRelevant(selectedSource, ChannelName.INBOUND);

  const { data: inboundStats, isFetching: isFetchingInboundStats } = useGetJobInboundStatsQuery(
    showStats
      ? {
          id: jobId,
          ...dateRange,
          channelName: selectedSource?.channelName,
          candidateSource: selectedSource?.candidateSource,
        }
      : skipToken
  );

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <B2_doNotUse>
        {isFetchingInboundStats ? (
          <DoverLoadingSpinner spinnerSize="14px" minHeight="14px" height="14px" width="14px" />
        ) : (
          <>
            <strong>
              {inboundStats?.numApplicantsToReview === undefined || !showStats ? (
                <>--</>
              ) : (
                <>{inboundStats?.numApplicantsToReview}</>
              )}
            </strong>{" "}
            applicant
            {inboundStats?.numApplicantsToReview == 1 ? "" : "s"}
          </>
        )}
      </B2_doNotUse>
    </Box>
  );
};
