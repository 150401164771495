import { Autocomplete } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";

import { StyledListItem, StyledTextField } from "components/dover/inputs/pro-users/styles";
// import { useQueryParamWithDefault } from "hooks/useQueryParamWithDefault";
import { useQueryParamWithDefault } from "hooks/useQueryParamWithDefault";
import { B2_doNotUse } from "styles/typography";
import { ALL_CHANNELS_OPTION, ChannelFilterLabelEnum, SourceOptionParam } from "views/Reporting/constants";
import { useListActiveCandidateSources } from "views/Reporting/hooks";
import { SourceOption } from "views/Reporting/types";
import { getSourceOptions, isQueriedSourceOptionValid } from "views/Reporting/utils";

export interface OnChannelDropdownChangeOptions {
  sourceOption: SourceOption;
  didChannelChange: boolean;
  shouldPerformSideEffects: boolean;
}
interface ChannelsDropdownSelectorProps {
  jobId: string;
  onChange: (options: OnChannelDropdownChangeOptions) => void;
}

export const ChannelsDropdownSelector = React.memo(
  ({ jobId, onChange }: ChannelsDropdownSelectorProps): React.ReactElement => {
    const {
      data: activeCandidateSources,
      isFetching: isFetchingActiveCandidateSources,
    } = useListActiveCandidateSources(jobId);

    const data: SourceOption[] = useMemo(() => {
      if (!isFetchingActiveCandidateSources && activeCandidateSources) {
        return getSourceOptions(activeCandidateSources);
      }
      return [];
    }, [activeCandidateSources, isFetchingActiveCandidateSources]);

    const [sourceOption, setSourceOption] = useQueryParamWithDefault<SourceOption>(
      {
        paramName: "sourceOption",
        paramType: SourceOptionParam,
        defaultValue: ALL_CHANNELS_OPTION,
        isDataFetching: isFetchingActiveCandidateSources,
        valueChecker: (queriedSourceOption: SourceOption | null | undefined): boolean => {
          return isQueriedSourceOptionValid(queriedSourceOption, data);
        },
      },
      [data]
    );

    const activeChannel = useRef<string | undefined>(undefined);

    // Whenever the set of valid SourceOptions from the API updates, a new value (possibly default) will be retrieved.
    // We need to reflect this in the URL and inform the parent component.
    useEffect(() => {
      if (isFetchingActiveCandidateSources) {
        return;
      }

      onChange({
        sourceOption,
        didChannelChange: sourceOption.value.channelName !== activeChannel.current,
        shouldPerformSideEffects: false,
      });
      activeChannel.current = sourceOption.value.channelName;
    }, [sourceOption, isFetchingActiveCandidateSources, onChange]);

    return (
      <Autocomplete
        sx={{
          legend: { display: "none" },
          ".MuiInputBase-root": {
            border: "none",
          },
        }}
        disableClearable
        value={sourceOption}
        onChange={(_event, newSourceOption: SourceOption): void => {
          if (newSourceOption) {
            onChange({
              sourceOption: newSourceOption,
              didChannelChange: newSourceOption.value.channelName !== activeChannel.current,
              shouldPerformSideEffects: true,
            });
            setSourceOption(newSourceOption);
          }
        }}
        isOptionEqualToValue={(option, value): boolean => {
          return option.label === value.label;
        }}
        loading={isFetchingActiveCandidateSources}
        options={data}
        renderInput={(params): React.ReactElement => {
          return <StyledTextField {...params} size="small" />;
        }}
        renderOption={(props, option: SourceOption): React.ReactElement => {
          if (Object.values(ChannelFilterLabelEnum).includes(option.label as ChannelFilterLabelEnum)) {
            return (
              // note: key prop must be declared last so that MUI's props.key (getOptionLabel) does
              // not override provided key
              // @ts-ignore
              <StyledListItem {...props} key={`${option.value.channelName}-${option.label}`}>
                <B2_doNotUse>{option.label}</B2_doNotUse>
              </StyledListItem>
            );
          }

          return (
            // @ts-ignore
            <StyledListItem {...props} key={`${option.value.channelName}-${option.label}`}>
              <B2_doNotUse style={{ paddingLeft: "24px" }}>{option.label}</B2_doNotUse>
            </StyledListItem>
          );
        }}
      />
    );
  }
);
