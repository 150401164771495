/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useQueryParam, QueryParamConfig, UrlUpdateType } from "use-query-params";

type valueChecker<QueriedValueType> = (queriedValue: QueriedValueType | null | undefined) => boolean;

interface QueryParamWithDefaultOptions<DecodedParamType> {
  /**
   * Named key to search for in URL parameters
   */
  paramName: string;
  /**
   * Type of object providing encoding/decoding functionality
   */
  paramType: QueryParamConfig<DecodedParamType | null | undefined>;
  /**
   * If querying fails or queried value is invalid, fall back to this defaultValue
   */
  defaultValue: DecodedParamType;
  /**
   * If provided and true, defaultValue will be returned
   */
  isDataFetching?: boolean;
  /**
   * Returns true if the passed in value is valid. Returns false otherwise.
   */
  valueChecker?: valueChecker<DecodedParamType>;
}

export function useQueryParamWithDefault<DecodedParamType>(
  { paramName, paramType, defaultValue, isDataFetching, valueChecker }: QueryParamWithDefaultOptions<DecodedParamType>,
  dependencies: any[]
): [DecodedParamType, (newValue: DecodedParamType, updateType?: UrlUpdateType | undefined) => void] {
  // return some value
  // that value is computed by a useEffect
  // the useEffect runs whenever the query OR any dependencies change
  // The value is tracked by useState
  // No need for memoization - just useState, and an effect that updates on query value
  const [computedValue, setComputedValue] = useState<DecodedParamType>(defaultValue);
  const [queryValue, setQueryValue] = useQueryParam(paramName, paramType);

  useEffect(() => {
    // We won't manipulate the state value for now, just preserve the initial value until data fetching is complete
    if (isDataFetching) {
      return;
    }
    // If we didn't successfully decode and the value is undefined rather than null, the URL param has a nonsense value.
    // In this case, remove the param from the URL.
    if (!queryValue) {
      if (queryValue === null) {
        setQueryValue(undefined);
      }

      return;
    }

    // Simple case where we only care about whether decoding succeeded or not.
    // Useful for enum encoding, for example.
    if (!valueChecker) {
      setComputedValue(queryValue);
      return;
    }

    // In this more complex case, we know the query decoding was successful, but we still want the caller to validate the decoded value.
    // If caller returns true, we set the query value on state. Otherwise, we remove the param from the URL.
    const isValidValue = valueChecker(queryValue);
    if (isValidValue) {
      setComputedValue(queryValue);
    } else {
      setQueryValue(undefined);
    }
  }, [queryValue, paramName, isDataFetching, valueChecker, ...dependencies]);

  return [computedValue, setQueryValue];
}
