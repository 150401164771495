import React from "react";

import { B2_doNotUse } from "styles/typography";

interface ReferralsUploadedConnectionsProps {
  numUsersUploadedConnections: number | undefined;
  showStats?: boolean;
}

export const ReferralsUploadedConnections = ({
  numUsersUploadedConnections,
  showStats = true,
}: ReferralsUploadedConnectionsProps): React.ReactElement => {
  return (
    <B2_doNotUse>
      <strong>{showStats ? <>{numUsersUploadedConnections}</> : <>--</>}</strong>{" "}
      {numUsersUploadedConnections === 1 ? "colleague" : "colleagues"} uploaded connections
    </B2_doNotUse>
  );
};
